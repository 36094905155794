// CURRENT CODE
// import React, { Component, useState } from "react";
// import "./App.css";
// import { Route, Switch, withRouter, useHistory } from "react-router-dom";

// IVO CODE
import React, { useState, Component } from "react";
import "./App.css";
import { Route, Switch, withRouter, useHistory } from "react-router-dom";

// Bootstrap
// import "bootstrap/dist/css/bootstrap.css";

//Landing Page
import LandingPage from "./LandingPage/LandingPage";

//Nav
import Nav from "./Nav/index";
// Footer
// import Footer from "./Footer/index";

import MainContainer from "./MainContainer";
import WelcomeComponent from "./WelcomeComponent";
import RegisterLoginContainer from "./RegisterLoginContainer";
import Login from "./RegisterLoginContainer/Login";
// IVO's FILE
// import Register from "./RegisterLoginContainer/Register.jsx";
import Register from "./RegisterLoginContainer/Register.js";
import HomeContainer from "./HomeContainer";
import About from "./About";
import MyCasaContainer from './MyCasaComponents/MyCasaContainer';

import MyCasaDashboard from "./MyCasaComponents/MyCasaDashboard";
import MyProjectComponent from "./MyProjectComponents";
// import ProjectPlan from './MyProjectComponents/ProjectPlan';

import ProjectsContainer from './ProjectsComponents/ProjectsContainer/ProjectsContainer.js';
import MyAccountContainer from "./MyAccountComponents/MyAccountContainer";
import EditMyAccount from "./MyAccountComponents/EditMyAccount";
import ViewTermsOfUse from "./MyAccountComponents/ViewTermsOfUse";

import ClothesDrying from "./ElectrifyComponents/ClothesDrying";
import Cooking from "./ElectrifyComponents/Cooking";
import ElectricalPanel from "./ElectrifyComponents/ElectricalPanel";
import ElectricVehicle from "./ElectrifyComponents/ElectricVehicle";
import EnergyGeneration from "./ElectrifyComponents/EnergyGeneration";
import HeatingCooling from "./ElectrifyComponents/HeatingCooling";
import HotWater from "./ElectrifyComponents/HotWater";
import Weatherization from "./ElectrifyComponents/Weatherization";

import CreateHouse from "./MyCasaComponents/HouseContainer/CreateHouse";
import CreateRoof from "./MyCasaComponents/RoofContainer/CreateRoof";
import CreateAttic from "./MyCasaComponents/AtticContainer/CreateAttic";
import CreateWaHeater from "./MyCasaComponents/WaHeaterContainer/CreateWaHeater";
import CreateSpHeater from "./MyCasaComponents/SpHeaterContainer/CreateSpHeater";
import CreateUtility from "./MyCasaComponents/UtilityContainer/CreateUtility";

import EditHouse from "./MyCasaComponents/HouseContainer/EditHouse";
import EditRoof from "./MyCasaComponents/RoofContainer/EditRoof";
import EditAttic from "./MyCasaComponents/AtticContainer/EditAttic";
import EditWaHeater from "./MyCasaComponents/WaHeaterContainer/EditWaHeater";
import EditSpHeater from "./MyCasaComponents/SpHeaterContainer/EditSpHeater";
import EditUtility from "./MyCasaComponents/UtilityContainer/EditUtility";

import AdminContainer from "./Admin/AdminPages/AdminHome";
import AdminShow from "./Admin/AdminPages/AdminShow";

import userService from "./utils/authService";

import LoadingPage from "./Shared/Loading/Loading"

const My404 = () => {
  return <div>It cho ke!</div>;
};

const App = () => {
  // if (sessionStorage.getItem('userId') !== null) {
  //   console.log('USER IS LOGGED IN')
  // } else if(props.location.pathname !== '/') {
  //   props.history.push('/')
  // }
  /**
*/
  // using React useState hook to set the user variable
  const [user, setUser] = useState(userService.getUser());
  
  const history = useHistory()

  function handleSignUpOrLogin(){
    setUser(userService.getUser())
    console.log(user, "<------user")
  }

  function handleLogout(){
    console.log("logout")
    userService.logout();
    setUser({user: null});
    history.push("/");
  }

  return (
    <main>
      {/* {user?
        <Nav userId={user._id} handleLogout={handleLogout}/>
      :
        <Nav />
      } */}
      
      <Switch>
        {/* PREVIOUS CODE */}
         {/* <Route exact path="/">
          <MainContainer userId={user ? user._id : ''} handleLogout={handleLogout}/>
        </Route> */}
        <Route exact path="/loading">
          <LoadingPage />
        </Route>
        <Route exact path="/login">
          <RegisterLoginContainer handleSignUpOrLogin={handleSignUpOrLogin} history={history} redirectPage={user ? `/mycasa/${user._id}` : '/home'}/>
        </Route>  
        <Route exact path="/signup">
          <Register handleSignUpOrLogin={handleSignUpOrLogin} history={history} redirectPage={user ? `/mycasa/${user._id}` : '/home'}/>
        </Route>
        <Route exact path="/home">
          <HomeContainer userId={user ? user._id : ''} handleLogout={handleLogout}/>
        </Route> 
        <Route exact path="/home/welcome">
          <WelcomeComponent userId={user ? user._id : ''} handleLogout={handleLogout}/>
        </Route> 
        <Route exact path="/about">
          <About userId={user ? user._id : ''} handleLogout={handleLogout}/>
        </Route> 
        <Route exact path="/myaccount/:id">
          <MyAccountContainer user={user} userId={user ? user._id : ''} history={history} handleLogout={handleLogout} renderNav={true}/>
        </Route>
        <Route exact path="/myaccount/:id/edit">
          <EditMyAccount handleSignUpOrLogin={handleSignUpOrLogin} user={user} userId={user ? user._id : ''} history={history} handleLogout={handleLogout}/>
        </Route>
        <Route exact path="/agreement" component={ViewTermsOfUse} userId={user ? user._id : ''} handleLogout={handleLogout}/>

        {/* ADMIN */}
        <Route exact path="/admin">
          <RegisterLoginContainer handleSignUpOrLogin={handleSignUpOrLogin} history={history} redirectPage={'/admin/home'}/>
        </Route> 
        <Route exact path="/admin/home">
          <AdminContainer user={user} handleLogout={handleLogout} history={history}/>
        </Route> 

        <Route exact path="/admin/:id">
          <AdminShow user={user} handleLogout={handleLogout} page='Data' history={history}/>
        </Route> 
        
        {/* //Elecctrify */}

        {/* IVO CODE */}
        <Route exact path="/" component={() => <LandingPage  userId={user ? user._id : ''} handleLogout={handleLogout}/>} />
        {/* <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Register} />
        <Route exact path="/home" component={HomeContainer} />
        <Route exact path="/home/welcome" component={WelcomeComponent} />
        <Route exact path="/myaccount/:id" component={MyAccountContainer} />
        <Route exact path="/myaccount/:id/edit" component={EditMyAccount} />
        <Route exact path="/agreement" component={ViewTermsOfUse} />
        <Route exact path="/admin/home" component={AdminContainer} />
        //Elecctrify */}
        
        {/* CONTINUE EXISTING CODE */}
        <Route exact path="/home/clothesdrying" component={() => <ClothesDrying userId={user ? user._id : ''} handleLogout={handleLogout}/>} />
        <Route exact path="/home/cooking" component={() => <Cooking userId={user ? user._id : ''} handleLogout={handleLogout}/>} />
        <Route exact path="/home/electricalpanel" component={() => <ElectricalPanel userId={user ? user._id : ''} handleLogout={handleLogout}/>} />
        <Route exact path="/home/electrivehicle" component={() => <ElectricVehicle userId={user ? user._id : ''} handleLogout={handleLogout} />} />
        <Route exact path="/home/energygeneration" component={() => <EnergyGeneration userId={user ? user._id : ''} handleLogout={handleLogout} />} />
        <Route exact path="/home/heatingcooling" component={() => <HeatingCooling userId={user ? user._id : ''} handleLogout={handleLogout} />} />
        <Route exact path="/home/hotwater" component={() => <HotWater userId={user ? user._id : ''} handleLogout={handleLogout} />} />
        <Route exact path="/home/weatherization" component={() => <Weatherization userId={user ? user._id : ''} handleLogout={handleLogout} />} />
        {/* //MyCasa */}
        <Route exact path="/mycasa/:id">
          <MyCasaContainer user={user} userId={user ? user._id : ''} handleLogout={handleLogout} />
          {/* <MyCasaDashboard user={user} userId={user ? user._id : ''} handleLogout={handleLogout}/> */}
        </Route> 
        <Route exact path="/mycasa/house/create">
          <CreateHouse user={user} userId={user ? user._id : ''} handleLogout={handleLogout}/>
        </Route> 
        <Route exact path="/mycasa/roof/create">
          <CreateRoof user={user} userId={user ? user._id : ''} handleLogout={handleLogout}/>
        </Route> 
        <Route exact path="/mycasa/attic/create">
          <CreateAttic user={user} userId={user ? user._id : ''} handleLogout={handleLogout}/>
        </Route>
        <Route exact path="/mycasa/waheater/create">
          <CreateWaHeater user={user} userId={user ? user._id : ''} handleLogout={handleLogout}/>
        </Route>
        <Route exact path="/mycasa/spheater/create">
          <CreateSpHeater user={user} userId={user ? user._id : ''} handleLogout={handleLogout}/>
        </Route>
          
        <Route exact path="/mycasa/utility/create">
          <CreateUtility user={user} userId={user ? user._id : ''} handleLogout={handleLogout}/>
        </Route> 
        <Route exact path="/mycasa/house/edit">
          <EditHouse user={user} userId={user ? user._id : ''} handleLogout={handleLogout} history={history} renderNav={true} />
        </Route>
        <Route exact path="/mycasa/roof/edit">
          <EditRoof user={user} userId={user ? user._id : ''} handleLogout={handleLogout} history={history} renderNav={true} />
        </Route>
        <Route exact path="/mycasa/attic/edit">
          <EditAttic user={user} userId={user ? user._id : ''} handleLogout={handleLogout} history={history} renderNav={true} />
        </Route> 
        <Route exact path="/mycasa/waheater/edit">
          <EditWaHeater user={user} userId={user ? user._id : ''} handleLogout={handleLogout} history={history} renderNav={true} />
        </Route>
        <Route exact path="/mycasa/spheater/edit">
          <EditSpHeater user={user} userId={user ? user._id : ''} handleLogout={handleLogout} history={history} renderNav={true} />
        </Route>
        <Route exact path="/mycasa/utility/edit">
          <EditUtility user={user} userId={user ? user._id : ''} handleLogout={handleLogout} history={history} renderNav={true} />
        </Route>
        {/* //MyProject */}
        <Route exact path="/MyProject/:id">
          <ProjectsContainer user={user} userId={user ? user._id : ''} handleLogout={handleLogout} renderNav={true}/>
        </Route>
      </Switch>
    </main>
  );
};

export default withRouter(App);
// <Route exact path="/MyProject/:id/plan" component = { ProjectPlan} />