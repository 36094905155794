// toggle from jmh86 on codepen --> https://codepen.io/jmh86/pen/PoNmdOO
import React, { Component } from "react";
import './PhotoGrade.css';

class PhotoGrade extends Component {
    constructor(props) {
      super(props);
    }
    render () {
      return (
        <div className="grade-container" style={{backgroundColor: this.props.GradeColor}}>
            <div className="left-box"><p>BEE</p></div>
            <div className="right-box" ><p>{this.props.BuildingEfficiencyGrade + '-' + this.props.ElectrificationGrade}</p></div>
        </div>
      );
    }
  }
  
  export default PhotoGrade;