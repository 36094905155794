import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import Nav from '../../../Nav';
import LoadingPage from '../../../Shared/Loading/Loading';
import ReactTooltip from "react-tooltip";
import * as PhotoService from '../../../utils/photoUploadService';
import tokenService from '../../../utils/tokenService';
import PhotoUploadLink from '../../../Shared/PhotoUploadLink/PhotoUploadLink';
import ValidationMessage from '../../../Shared/ValidationMessage/ValidationMessage';
import * as FormService from '../../../utils/formService';

class CreateSpHeater extends Component {
  constructor(props){
    super(props);
    this.state = {
      spHeater : {
        spHeaterImg: [],
        spHeaterType : '',
        spHeaterBrand: '',
        spHeaterYear: '',
        spHeaterCondition: '',
        coolingSystem: '',
        userId: '',
        valid: {
          spHeaterType: false,
          spHeaterYear: false,
        }
      },
      preview: null,
      selectedFile : null,
      submitted: false,
      loading: false,
    }
    // this.checkIfValid = this.checkIfValid.bind(this);
    // this.handleInput = this.checkIfValid.bind(this);
  };

  handleInput = e => {
    FormService.handleInput.call(this, e, 'spHeater', ['spHeaterType', 'spHeaterYear']);


  };

  handleClick = e => {
    var frame = document.getElementById(`input-${e.target.id}`);
    frame.click();
  }

  handleSubmit = e => {
    e.preventDefault();
    console.log(this.state.spHeater, "<--= spHeater from submit");
    
    // validations for photo, heater type, and heater year
    if(this.state.spHeater.valid.spHeaterType && this.state.spHeater.spHeaterCondition && this.state.spHeater.valid.spHeaterYear && this.state.preview1){
    
    this.setState({
      ...this.state,
      loading: true,
    })
    const updatedspHeater = {
      ...this.state.spHeater
    }

    this.addspHeater(updatedspHeater);

    this.setState({
      spHeater : {
        spHeaterImg: [],
        spHeaterType : '',
        spHeaterBrand: '',
        spHeaterYear: '',
        spHeaterCondition: '',
        coolingSystem: '',
        userId: '',
      }
    });
  } else {
    this.setState({
      ...this.state,
      submitted: true,
    })
  }

};

  fileSelectHandler = async function (e) {
    let objectName = "spHeater";
    await PhotoService.handleUploadFile.call(this, e, objectName);

  }.bind(this);


    addspHeater = async(updatedspHeater) => {
        const data = new FormData();
        let lastphoto = this.state.spHeater.spHeaterImg.length - 1;
        data.append('spHeaterImg', this.state.spHeater.spHeaterImg[lastphoto], "SpaceHeaterImage.jpg");
        data.append('spHeaterType', this.state.spHeater.spHeaterType);
        data.append('spHeaterBrand', this.state.spHeater.spHeaterBrand);
        data.append('spHeaterYear', this.state.spHeater.spHeaterYear);
        data.append('spHeaterCondition', this.state.spHeater.spHeaterCondition);
        data.append('coolingSystem', this.state.spHeater.coolingSystem);
        // data.append('time', this.state.house.time);

        let userId = this.props.user._id;
        data.append('userId', userId)

        const time = new Date();
        data.append('postingTime', time)

        axios.post(`${process.env.REACT_APP_API}/api/v1/spHeater`, data, {
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization': 'Bearer ' + tokenService.getToken()
          }
        })
        .then(res => {
          if (res.status === 200) {
            this.props.history.push('/mycasa/' +  this.props.user._id);
        }
      })
    }

//"Geothermal Heat Pump", ele
//"Pellet Stove", wood

  render(){

    //redirects user to mycasa page once form data is saved to db
    // if (this.state.submitted) {
    //   this.props.history.push('/mycasa/' +  this.props.user._id);
    // }

    const spHeaterTypeOptions = ["Select", "Central Gas Furnace", "Room Gas Furnace", "Oil Furnace", "Electric Resistance", "Electric Heat Pump", "Electric Mini-Split", "Gas Boiler/Radiant", "Geothermal Heat Pump", "Wood Stove", "Pellet Stove"];
    const coolingSystemOptions = ["Select", "None", "Room Unit", "Central Air"];
    const spHeaterYearOptions = ["Select"];
    let today = new Date()
    let cuttentYear = today.getFullYear();

    for(let i = cuttentYear; i >= 1980; i-=1){
        let yr = i
        spHeaterYearOptions.push(yr.toString())
    }

    const upload = "./../../../upload.svg"

    const sampleSpHeaterImg = "./../../SampleImages/SpHeaterSample.jpg";


    if(this.state.loading){
      return <LoadingPage />
    }else {

    return(

      <div>
        <Nav userId={this.props.userId} handleLogout={this.props.handleLogout}/>
        <div className="createContainer">
          <div className="createTitle">Heating and Cooling Details</div>
          <form onSubmit={this.handleSubmit}>
            <div className="createBox">
              <div className="helpContainer">
                <span className="inputLabel">PHOTO *</span>
                <img data-tip data-for="helpTip" className="help" src="./../../help.svg"/>
                <ReactTooltip id="helpTip" place="bottom" effect="solid" className="tooltipContainer">
                  <img className="sampleImg" src={sampleSpHeaterImg}/>
                  <div className="sampleText">Use your flash if needed to take a photo of your space heater, making sure to capture the serial number.</div>
                </ReactTooltip>
                
              </div>
              <div className="frames" style={this.state.submitted && !this.state.preview1 ? {border: '1px dashed #D22E2E'}: {}}>
              {this.state.preview1 ? <img className="imgAttached" id="photoOne" src={this.state.preview1}  onClick={this.handleClick } /> 
                : <div  id="photoOne" onClick={this.handleClick }><PhotoUploadLink />
                {this.state.submitted ? <ValidationMessage text={'Please upload a photo.'}/> : ''}
                </div>}
                {/* <img className={this.state.preview1 ? "imgAttached" : "placer"}  id="photoOne" src={this.state.preview1 ? this.state.preview1 :  upload} onClick={this.handleClick } /> */}
                <input name="photoOne" className="fileUpload" id="input-photoOne" onChange={this.fileSelectHandler} type="file"/>
              </div>
               <div className="inputContainer">
                <label className="inputLabel" htmlFor="spHeaterType">TYPE OF SPACE HEATER *
                  <span className="tipContainer">
                    <img data-tip data-for="spHeaterType" className="help" src="./../../help.svg"/>
                    <ReactTooltip id="spHeaterType" place="bottom" effect="solid" className="tip">
                      <div className="tipText">Email north@housegrades.com if you can’t determine what type of equipment you have.</div>
                    </ReactTooltip>
                  </span>
                </label>
                <select className={!this.state.spHeater.valid.spHeaterType && this.state.submitted ? "selectInput notValid" : "selectInput"} name="spHeaterType" id="spHeaterType" type="text" onChange={this.handleInput} value={this.state.spHeater.spHeaterType}>
                  {spHeaterTypeOptions.map(spHeaterTypeOption => {
                      return <option value={spHeaterTypeOption} key={spHeaterTypeOption} >{spHeaterTypeOption}</option>
                  })}
                </select>
                {!this.state.spHeater.valid.spHeaterType && this.state.submitted ? <ValidationMessage text={'Please enter a space heater type.'}/> : ''}
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="spHeaterBrand">BRAND</label>
                <input name="spHeaterBrand" id="spHeaterBrand" type="text" onChange={this.handleInput} value={this.state.spHeater.spHeaterBrand} />
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="spHeaterYear">YEAR OF MANUFACTURE *
                  <span className="tipContainer">
                    <img data-tip data-for="spHeaterYear" className="help" src="./../../help.svg"/>
                    <ReactTooltip id="spHeaterYear" place="bottom" effect="solid" className="tip">
                      <div className="tipText">Sometimes the year can be found on the device. If not, and you can read the serial number, you can look it up by visiting the website https://www.building-center.org/. For help, please email north@housegrades.com.</div>
                    </ReactTooltip>
                  </span>
                </label>
                <select className={!this.state.spHeater.valid.spHeaterYear && this.state.submitted ? "selectInput notValid" : "selectInput"} name="spHeaterYear" id="spHeaterYear" type="text" onChange={this.handleInput} value={this.state.spHeater.spHeaterYear}>
                  {spHeaterYearOptions.map(spHeaterYearOption => {
                      return <option value={spHeaterYearOption} key={spHeaterYearOption} >{spHeaterYearOption}</option>
                  })}
                </select>
                {!this.state.spHeater.valid.spHeaterYear && this.state.submitted ? <ValidationMessage text={'Please enter a year of manufacture.'}/> : ''}
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="spHeaterCondition">IS THE SYSTEM WORKING WELL? *
                  <span className="tipContainer">
                    <img data-tip data-for="spHeaterCondition" className="help" src="./../../help.svg"/>
                    <ReactTooltip id="spHeaterCondition" place="bottom" effect="solid" className="tip">

                      <div className="tipText">If your space heater provides enough heat to keep your house at a comfortable temperature, it is working well. If there are cold areas in the house, unusual noises or smells, it is not working well.</div>

                    </ReactTooltip>
                  </span>
                </label>
                <div id="spHeaterCondition">
                  <input name="spHeaterCondition" type="radio" checked={this.state.spHeater.spHeaterCondition === "Yes"} value="Yes" onChange={this.handleInput} /><span className="radioNext">YES</span>
                  <input name="spHeaterCondition" type="radio" checked={this.state.spHeater.spHeaterCondition === "No"} value="No" onChange={this.handleInput} className="radioInput-right" /><span className="radioNext">NO</span>
                </div>
              </div>
              {!this.state.spHeater.spHeaterCondition && this.state.submitted ? <ValidationMessage text={'Please select Yes or No.'}/> : ''}

              <div className="inputContainer">
                <label className="inputLabel" htmlFor="coolingSystem">IS THERE A SEPARATE COOLING SYSTEM?</label>
                <select className="selectInput" name="coolingSystem" id="coolingSystem" type="text" onChange={this.handleInput} value={this.state.spHeater.coolingSystem}>
                  {coolingSystemOptions.map(coolingSystemOption => {
                      return <option value={coolingSystemOption} key={coolingSystemOption} >{coolingSystemOption}</option>
                  })}
                </select>
              </div>
              <div className="inputContainer">
                <button type="submit" className="btn">SAVE</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
  }
}
export default withRouter(CreateSpHeater)
