import React from "react";
import {Link} from "react-router-dom";
import './ElectrifyNew.scss';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Nav from "../Nav";


const ClothesDrying = (props) => {
  return (
    <>
    <Nav userId={props.userId} handleLogout={props.handleLogout}/>
    <Container style={{marginTop: '4rem'}} >
    <Row>
            <Col md={12}>
                <Container className="home-link">
                    <Link to="/home">
                        <img style={{width: '14px'}} src="../SVG/Close.svg"/>
                    </Link>
                </Container>
            </Col>
            
      </Row>
      
      <Row>
        <div className="electrify-title"><h3 className="h3">{props.title}</h3></div>
      </Row>
      <Row>
        
          <Col md={3} >
          <div className="item_container">
          <div className="img_sub">
            <Row>
            <Col>
            <div className="electri_box">
              <img className="img" src={props.electrifyIcon} />
            </div>
            </Col>
            </Row>
            <Row>
            <Col>
            <span className="electri_text">{props.includedInRating}</span>
            {/* <div className="electri_text">Not Currently Included in Rating</div> */}
            </Col>
            </Row>
          </div>
          </div>
          </Col>
          <Col md={{span: 8, offset: 1}} >
          <div className="item_container">
          <div className="text_sub">
            <div className="p1">
            {props.p1 ? props.p1 : ""}
            </div>
            <div className="p2">
            {props.p2 ? props.p2 : ""}
            </div>
            <div className="p3">{props.p3 ? props.p3 : ""}</div>
            <div className="p4">{props.p4 ? props.p4 : ""}</div>
          </div>
          </div>
          </Col>
        
        </Row>
      
    </Container>
    </>
  );
};

export default ClothesDrying;
