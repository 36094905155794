import React from "react";
import ElectrifyLayout from './ElectrifyLayout';
import Nav from "./../Nav";
import './ElectrifyComponents.scss'

const ElectricalPanel = (props) => {
  return (


<ElectrifyLayout
          userId={props.userId} handleLogout={props.handleLogout}
          title="Electrical Panel"
          electrifyIcon="../Electrify/ElectricalPanel.svg"
          includedInRating='Not Rated ("NR") in Electrification Grade'
          p1="All this juice for electric heat pumps and appliances will need to
              be routed through your electrical panel. Most homes that currently
              are dependent on fossil fuels have relatively small electrical
              panels. A necessary change to enable electrification is having
              at least a 100 amp electrical panel for a 3000 square foot or less
              home. There are several clever ways to avoid having to upgrade
              to a larger panel, including circuit-sharing plugs and smart
              combo appliances that have internal controls."
          p2="There is a wide range of estimates for upgrading electrical
              panels and it is best to avoid it, or to include it at the same
              time as doing another improvement."

    />

  );
};

export default ElectricalPanel;
