import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import '../../MyCasaDashboard/MyCasaDashboard.scss';
import { Card, Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap';


const ShowRoof = (props) => {
  let roof = props.roof ||
    {
      roofImg: [],
      exterior : '',
      roofColor: '',
      pvSystem: '',
      panels: '',
      dcCapacity: '',
      userId: '',
    }

  const photo = roof.roofImg;
  const userId = props.user._id;

  const undisplayedProps = ["_id", "userId", "__v", "roofImg"]
  const displayPropsArr = Object.keys(roof).filter((allProps) =>
    !undisplayedProps.includes(allProps));
  // console.log(displayPropsArr, "<-----displaypropsarr");

  // uses map function to format and populate quick show with display props
  let items = displayPropsArr.map(function (item, index){
    if(item === "roofColor"){
      return (
              <div key={index}>
                {roof[item]
                ?
                <>
                  <span className="checkNext"><img src="./../checkMark.svg" /></span>
                  <span>Roof Age: {roof[item]}</span>
                </>
                :
                <>
                  <span className="checkNext gray"><img src="./../checkMark.svg" /></span>
                  <span className="gray">Roof Age</span>
                </>
                }
              </div>
            )
    
    } else {
      return (
              <div key={index}>
                {roof[item]
                ?
                <>
                  <span className="checkNext"><img src="./../checkMark.svg" /></span>
                  <span>{item[0].toUpperCase() + item.split(/(?=[A-Z])/).join(' ').slice(1)}: {roof[item]}</span>
                </>
                :
                <>
                  <span className="checkNext gray"><img src="./../checkMark.svg" /></span>
                  <span className="gray">{item[0].toUpperCase() + item.split(/(?=[A-Z])/).join(' ').slice(1)}</span>
                </>
                }
              </div>
            )
    }
    
    
  })

  // sets custom bootstrap toggle with expansion awareness
  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    // const currentEventKey = useAccordionEventKey();;
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <div className="detailArr"><img className="down" src={!isCurrentEventKey ? "./../../dropdown_drop.svg" : "./../../dropdown_up.svg"} /></div>
    );
  }


  return(
    <div className="postedContainer">
      <div className="postedFrame">
      {props.roof ?
        <Link to={"/mycasa/roof/edit"}><img className="postedImg" src={photo}/></Link>
      :
        <Link to="/mycasa/roof/create" className="blankContainer">
          <div className="blankFrame"><img className="placer" src="./../../upload.svg" /></div>
        </Link>
      }
      </div>
      <Accordion className="accordion">
        <Card className="card">
          <Card.Header className="header">
            <Accordion.Toggle variant="link" eventKey="0" className="toggleShow">
              <div className="postedLabel">Roof Details</div>
              <ContextAwareToggle eventKey="0"></ContextAwareToggle>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="cardBody">
              <div className="postedInfo">
                {/* <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.roof.exterior}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span>Roof Age: <span>{props.roof.roofColor}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span>PV System: <span>{props.roof.pvSystem}</span></div>
                <div>{props.roof.pvSystem === "No" ? <span></span> : <span><span className="checkNext"><img src="./../checkMark.svg" /></span>{props.roof.panels} <span>Panels</span></span>}</div>
                <div>{props.roof.pvSystem === "No" ? <span></span> : <span><span className="checkNext"><img src="./../checkMark.svg" /></span>{props.roof.dcCapacity}<span>kW DC Capacity</span></span>}</div> */}
                {items}
              </div>
            </Card.Body>
           </Accordion.Collapse>
          </Card>
        </Accordion>
    </div>
  );
};

export default ShowRoof;
