import React from "react";
import './ElectrifyNew.scss';

import ElectrifyLayout from './ElectrifyLayout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Nav from "./../Nav";


const ClothesDrying = (props) => {
  return (

    <ElectrifyLayout
          userId={props.userId} handleLogout={props.handleLogout}
          title="Clothes Drying"
          electrifyIcon="../Electrify/ClothesDrying.svg"
          includedInRating='Not Rated ("NR") in Electrification Grade'
          p1="Drying clothes requires a lot of dry heat. Gas is a good fuel for
              that. From a climate perspective, clothes drying is not
              responsible for a substantial amount of emissions. Approximately
              4% of residential home energy usage is for laundry. At this time,
              we have excluded it from our ratings."
          p2="A true all-electric home with zero emissions would exclude gas for
              clothes drying. Luckily, there are excellent alternatives. The
              same heat pump technology for water heating and space heating has
              been applied to clothes drying. The units today still take longer
              to dry clothes than gas, but they are rapidly improving."
            p3="The price for a heat pump clothes dryer starts at appoximately $1000."
    />



  );
};

export default ClothesDrying;
