import React from 'react';
import {Link} from 'react-router-dom';
import './AdminTableRow.css'

export default function AdminTableRow({address}){

    function getAge(year){
        const currentYear = new Date().getFullYear()
        return currentYear - year;
    }

    function getStatus(){
        if(address.verified){
            return "Verified"
        } else if (address.completed){
            return "Completed"
        } else {
            return "Draft"
        }
    }

    return(
        
        <tr >
        
            <td className="admin-table">{address.user ? address.user.firstName + " " + address.user.lastName : ""}</td>
            <td>
            <Link
                    to={`/admin/${address ? address._id : ''}`}
                    className="links"
                    style={{ textDecoration: "none" }}
                > 
            {address.house ? address.house.address : ""}
            </Link>
            </td>
            <td>{address.house ? address.house.city : ""}</td>
            <td>{address.house ? address.house.state : ""}</td>
            <td>{address.house ? address.house.zipcode : ""}</td>
            <td>{address.attic ? address.attic.atticDepth : ""}</td>
            <td>{address.waHeater ? getAge(address.waHeater.waHeaterYear) : ""}</td>
            <td>{address.spHeater ? getAge(address.spHeater.spHeaterYear) : ""}</td>
            <td>{getStatus()}</td>
        
        </tr>
        
    )
}