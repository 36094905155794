import React, { Component } from "react";
import { Link } from "react-router-dom";

import Table from 'react-bootstrap/Table';

import Nav from "../../Nav";
import "./../MyAccount.scss";

class MyAccountContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userinfo: this.props.user,
    };
  }

  // componentDidMount() {
  //   this.getMyinfo();
  //}

  // getMyinfo = async () => {
  //   const userId = sessionStorage.getItem("userId");

  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API}/api/v1/auth/` + userId,
  //       {
  //         credentials: "include",
  //       }
  //     );

  //     if (!response.ok) {
  //       throw Error(response.statusText);
  //     }

  //     const userParsed = await response.json();

  //     this.setState({
  //       userinfo: userParsed.data,
  //     });
  //   } catch (err) {
  //     return err;
  //   }
  // };

  render() {
    // const userId = sessionStorage.getItem("userId");
    const userId = this.props.user._id;
    if (!userId) {
      this.props.history.push("/");
    }


    return (
      <div>
        {
          this.props.renderNav ?
          <Nav userId={this.props.userId} handleLogout={this.props.handleLogout}/>
          :
          ''
        }
        <div className="accountContainer">
          <div className="titleContainer">
            <div className="title h2">Account</div>
          </div>
          <div className="myInfoContainer">
            <Table>
            <tbody>
              <tr>
                <td>
                  <div className="dh">Name</div>
                </td>
                <td>
                  {this.state.userinfo.firstName +
                    " " +
                    this.state.userinfo.lastName}
                </td>
                <td>
                  <Link className="account-link" id="edit-link" to={`/myaccount/${userId}/edit`}>Edit</Link>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Email Address</div>
                </td>
                <td>{this.state.userinfo.email}</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <div>Phone Number</div>
                </td>
                <td>{this.state.userinfo.phNumber}</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <div>Account Type</div>
                </td>
                <td>Free Plan</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <div>Email Notifications</div>
                </td>
                <td>{this.state.userinfo.emailNotice === "no" ? "Off" : "On"}</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <div>Mobile Notifications</div>
                </td>
                <td>{this.state.userinfo.mobileNotice === "no" ? "Off" : "On"}</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <div>Privacy and Settings</div>
                </td>
                <td>
                  <Link className="account-link" to="/agreement">Terms of Use</Link>
                </td>
                <td></td>
              </tr>
            </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default MyAccountContainer;
