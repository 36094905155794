import React from "react";
import ElectrifyLayout from './ElectrifyLayout';
import './ElectrifyComponents.scss'

const Weatherization = (props) => {
  return (

    <ElectrifyLayout
          userId={props.userId} handleLogout={props.handleLogout}
          title="Weatherization"
          electrifyIcon="../Electrify/Weatherization.svg"
          includedInRating="Impacts Building Efficiency Grade"
          p1="Weatherization is a bucket term that includes all the ways a house
              protects against the elements outside, including sunlight,
              precipitation, wind, cold and heat."
          p2="From an energy perspective, the measure that building professionals
              use most frequently as a diagnostic is airtightness. A house that
              is relatively airtight requires less energy to mantain at a
              comfortable temperature than one that is not."
          p3="Airtightness is impacted by insulation levels, quality of windows
              and overall sealing. For older homes, the project that has the
              best performance for its cost is proper air sealing and insulation
              in the attic. A well insulated attic increases comfort and reduces
              energy usage for heating and cooling. Efficiency upgrades should
              be every house's first step toward electrification."
          p4="The price for attic insulation and air sealing is typically
          under $2 per square foot, before rebates and incentives."
    />

  );
};

export default Weatherization;
