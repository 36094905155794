import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import LoadingPage from '../../../Shared/Loading/Loading';
import Nav from '../../../Nav';
import ReactTooltip from "react-tooltip";
import tokenService from '../../../utils/tokenService';
import * as PhotoService from '../../../utils/photoUploadService';
import PhotoUploadLink from '../../../Shared/PhotoUploadLink/PhotoUploadLink';
import ValidationMessage from '../../../Shared/ValidationMessage/ValidationMessage';
import * as FormService from '../../../utils/formService';

class CreateAttic extends Component {
  constructor(props){
    super(props);
    this.state = {
      attic : {
        atticImg: [],
        atticType : '',
        atticSqft: '',
        atticDepth: '',
        insulMaterial: '',
        airSealed: '',
        userId: '',
        valid: {
          atticDepth: false
        }
      },
      preview: null,
      selectedFile : null,
      submitted: false,
      loading: false
    }
  };

  handleInput = e => {
    FormService.handleInput.call(this, e, 'attic', ['atticDepth']);

    // const updatedChange = {
    //   ...this.state.attic
    // };

    // updatedChange[e.target.name] = e.target.value;

    // this.setState({
    //   attic: updatedChange
    // });
  };

  handleClick = e => {
    var frame = document.getElementById(`input-${e.target.id}`)
    frame.click();
  }

  handleSubmit = e => {
    e.preventDefault();
    if(this.state.attic.valid.atticDepth && this.state.attic.airSealed && this.state.preview1){


    this.setState({
      ...this.state,
      loading: true
    })
    const updatedAttic = {
      ...this.state.attic
    };

    this.addAttic(updatedAttic);

    this.setState({
      attic : {
        atticImg: null,
        atticType : '',
        atticSqft: '',
        atticDepth: '',
        insulMaterial: '',
        airSealed: '',
        userId: '',
      }
    });
    }else {
      this.setState({
        ...this.state,
        submitted: true,
      })
    }
  };


  fileSelectHandler = async function (e) {
    let objectName = "attic";
    await PhotoService.handleUploadFile.call(this, e, objectName);

  }.bind(this);

    addAttic = async(updatedHouse) => {
        const data = new FormData();
        let lastphoto = this.state.attic.atticImg.length - 1;

        data.append('atticImg', this.state.attic.atticImg[lastphoto], "atticImage.jpg");


        data.append('atticType', this.state.attic.atticType);
        data.append('atticSqft', this.state.attic.atticSqft);
        data.append('atticDepth', this.state.attic.atticDepth);
        data.append('insulMaterial', this.state.attic.insulMaterial);
        data.append('airSealed', this.state.attic.airSealed);
        // data.append('time', this.state.house.time);

        let userId = this.props.user._id;
        data.append('userId', userId);

        const time = new Date();
        data.append('postingTime', time);

        axios.post(`${process.env.REACT_APP_API}/api/v1/attic`, data, {
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization': 'Bearer ' + tokenService.getToken()
          }
        })
        .then(res => {
          if (res.status === 200) {
            this.props.history.push('/mycasa/' +  this.props.user._id);
          }
        })
      };

  render () {

  //redirects user to mycasa page once form data is saved to db
  // if (this.state.submitted) {
  //   this.props.history.push('/mycasa/' +  this.props.user._id);
  // }

  const atticTypeOptions = ["Select", "Accessible by Hatch or Door", "Not Accessible", "No Attic"];
  const atticDepthOptions = ["Select", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"];
  const insulMaterialOptions = ["Select", "Fiberglass Batt", "Fiberglass Blown", "Cellulose", "Not Sure"];
  let upload = "./../../../upload.svg"

  if(this.state.loading){
    return <LoadingPage />
  } else {

  return(
    <div>
      <Nav userId={this.props.userId} handleLogout={this.props.handleLogout}/>
      <div className="createContainer">
        <div className="createTitle">Attic Insulation Details</div>
        <form onSubmit={this.handleSubmit}>
          <div className="createBox">
            <div className="helpContainer">
              <span className="inputLabel">PHOTO *</span>
              <img data-tip data-for="helpTip" className="help" src="./../../help.svg"/>
              <ReactTooltip id="helpTip" place="bottom" effect="solid" className="tooltipContainer">
                <img className="sampleImg" src="./../../SampleImages/atticSample.png"/>
                <div className="sampleText">Use your flash to take a photo of your attic space, making sure to capture the depth of existing insulation. When opening your attic, you may want to wear a mask to avoid breathing in any material. </div>
              </ReactTooltip>
            </div>

            <div className="frames" style={this.state.submitted && !this.state.preview1 ? {border: '1px dashed #D22E2E'}: {}}>
            {this.state.preview1 ? <img className="imgAttached" id="photoOne" src={this.state.preview1}  onClick={this.handleClick } /> 
                : <div id="photoOne" onClick={this.handleClick }><PhotoUploadLink />
                {this.state.submitted ? <ValidationMessage text={'Please upload a photo.'}/> : ''}
                </div>}
              {/* <img className={this.state.preview1 ? "imgAttached" : "placer"}  id="photoOne" src={this.state.preview1 ? this.state.preview1 :  upload}  onClick={this.handleClick } /> */}
              <input className="fileUpload" name="photoOne" id="input-photoOne" onChange={this.fileSelectHandler} type="file"/>
            </div>
            <div className="inputContainer">
              <label className="inputLabel" htmlFor="atticType">PRIMARY ATTIC TYPE</label>
              <select className="selectInput" name="atticType" id="atticType" type="text" onChange={this.handleInput} value={this.state.attic.atticType} >
                {atticTypeOptions.map(atticTypeOption => {
                    return <option value={atticTypeOption} key={atticTypeOption} >{atticTypeOption}</option>
                })}
              </select>
            </div>
            <div className="inputContainer">
              <label className="inputLabel" htmlFor="atticSqft">ATTIC SQUARE FOOTAGE (GUESS)</label>
              <input name="atticSqft" id="atticSqft" type="text" onChange={this.handleInput} value={this.state.attic.atticSqft} />
            </div>
            <div className="inputContainer">
              <label className="inputLabel" htmlFor="atticDepth">
                INSULATION AVERAGE DEPTH (INCHES) *
                <span className="tipContainer">
                  <img data-tip data-for="tip" className="help" src="./../../help.svg"/>
                  <ReactTooltip id="tip" place="bottom" effect="solid" className="tip">
                    <div className="tipText">Use a tape measure to determine the thickness of the insulation material between the roof and the attic floor. Insulation should be full and continuous across the surface to be effective. If there are gaps, or a mix of low and high materials, enter the lower number in the range. For questions, email north@housegrades.com.</div>
                  </ReactTooltip>
                </span>
              </label>
              <select className={!this.state.attic.valid.atticDepth && this.state.submitted ? "selectInput notValid" : "selectInput"} name="atticDepth" id="atticDepth" type="text" onChange={this.handleInput} value={this.state.attic.atticDepth} >
                {atticDepthOptions.map(atticDepthOption => {
                    return <option value={atticDepthOption} key={atticDepthOption} >{atticDepthOption}</option>
                })}
              </select>
              {!this.state.attic.valid.atticDepth && this.state.submitted ? <ValidationMessage text={'Please enter an insulation depth.'}/> : ''}
            </div>
            <div className="inputContainer">
              <label className="inputLabel" htmlFor="insulMaterial">INSULATION MATERIAL</label>
              <select className="selectInput" name="insulMaterial" id="insulMaterial" type="text" onChange={this.handleInput} value={this.state.attic.insulMaterial} >
                {insulMaterialOptions.map(insulMaterialOption => {
                  return <option value={insulMaterialOption} key={insulMaterialOption} >{insulMaterialOption}</option>
                })}
              </select>
            </div>
            <div className="inputContainer">
              <label className="inputLabel" htmlFor="airSealed">HAS THE HOUSE BEEN PROFESSIONALLY AIR SEALED?*</label>
              <div id="airSealed" >
                <input name="airSealed" type="radio" checked={this.state.attic.airSealed === "Yes"} value="Yes" onChange={this.handleInput}/><span className="radioNext">YES</span>
                <input className="radioInput-right" name="airSealed" type="radio" checked={this.state.attic.airSealed === "No"} value="No" onChange={this.handleInput}/><span className="radioNext">NO</span>
              </div>
            </div>
            {!this.state.attic.airSealed && this.state.submitted ? <ValidationMessage text={'Please select Yes or No.'}/> : ''}
            <div className="inputContainer">
              <button type="submit" className="btn">SAVE</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    );
  }
  };
};

export default withRouter(CreateAttic);
