import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import Moment from 'react-moment';
import Nav from '../../../Nav';
import LoadingPage from '../../../Shared/Loading/Loading';
import ReactTooltip from "react-tooltip";
import * as PhotoService from '../../../utils/photoUploadService';
import editService from '../../../utils/editService';
import ValidationMessage from '../../../Shared/ValidationMessage/ValidationMessage';
import * as FormService from '../../../utils/formService';

class EditWaHeater extends Component {
  constructor(props){
    super(props)
    this.state = {
      waHeater : {
        waHeaterImg: [],
        waHeaterType : '',
        waHeaterBrand: '',
        waHeaterYear: '',
        waHeaterCondition: '',
        waHeaterSingle: '',
        userId: '',
        valid: {
          waHeaterType: false,
          waHeaterYear: false,
        }
      },
      preview1: null,
      selectedFile : null,
      loading: true,
      submitted: false
    }
  }

  componentDidMount(){
    this.getHouseInfo()
    this.setState({
      ...this.state,
      loading: false
    })
  };

  getHouseInfo = async() => {
    // const userId = window.location.pathname.split('/')[2];
    const userId = this.props.userId;

    try{
      const response = await fetch(`${process.env.REACT_APP_API}/api/v1/users/${userId}`,  {
        credentials: 'include'
      })

      if(!response.ok){
        throw Error(response.statusText)
      }

      const userParsed = await response.json();

      console.log(userParsed.waHeater, "UserParsed<------------")

      let waterHeaterInfo = userParsed.waHeater;
      waterHeaterInfo.waHeaterType = waterHeaterInfo.waHeatertype;
      waterHeaterInfo.valid = {};
      
      waterHeaterInfo.waHeaterType && waterHeaterInfo.waHeaterType !== 'Select' ? waterHeaterInfo.valid.waHeaterType = true : waterHeaterInfo.valid.waHeaterType = false;
      waterHeaterInfo.waHeaterYear && waterHeaterInfo.waHeaterYear !== 'Select' ? waterHeaterInfo.valid.waHeaterYear = true : waterHeaterInfo.valid.waHeaterYear = false;
      console.log(waterHeaterInfo, "spaceHeater Info<------- Validations")

      this.setState({
        waHeater: {...waterHeaterInfo,
        }

      // console.log(userParsed.waHeater);
      // this.setState({
      //     waHeater: {
      //       waHeaterImg: userParsed.waHeater.waHeaterImg,
      //       waHeaterType: userParsed.waHeater.waHeaterType,
      //       waHeaterBrand: userParsed.waHeater.waHeaterBrand,
      //       waHeaterYear: userParsed.waHeater.waHeaterYear,
      //       waHeaterCondition: userParsed.waHeater.waHeaterCondition,
      //       waHeaterSingle: userParsed.waHeater.waHeaterSingle
      //     },

      })
    }catch(err){
      return err
    }
  }

  handleInput = (e) => {
    console.log("handle input firing %%%%%%%%")
    FormService.handleInput.call(this, e, 'waHeater', ['waHeaterType', 'waHeaterYear']);
    
    // const updatedChange = {
    //   ...this.state.waHeater
    // }
    // updatedChange[e.target.name] = e.target.value;

    // this.setState({
    //   waHeater: updatedChange
    // })
  }

  handleClick = (e) => {
    var frame = document.getElementById(`input-${e.target.id}`)
    frame.click();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const updatedwaHeater = {
      ...this.state,
      loading: true
    }

    if(this.state.waHeater.waHeaterCondition && this.state.waHeater.valid.waHeaterType && this.state.waHeater.valid.waHeaterYear && (this.state.preview1 || this.state.waHeater.waHeaterImg)){

    this.editwaHeater(updatedwaHeater)
    //set empty after call the function
    this.setState({
      waHeater : {
        waHeaterImg: null,
        waHeaterType : '',
        waHeaterBrand: '',
        waHeaterYear: '',
        waHeaterCondition: '',
        waHeaterSingle: '',
        userId: '',
        valid: {}
      }
    })
  } else {
      this.setState({
        ...this.state,
        submitted: true,
    })
  }
  }

    fileSelectHandler = async function (e) {
      let objectName = "waHeater";
      await PhotoService.handleUploadFile.call(this, e, objectName);

    }.bind(this);

    // handleEditFormInput = (e) => {
    //   e.preventDefault()
    //   this.setState({
    //     waHeater: {
    //       ...this.state.waHeater,
    //       [e.target.name]:  e.target.value
    //     }
    //   })
    // }

    editwaHeater = async(e) => {
      // e.preventDefault();
      // this.setState({
      //   ...this.state,
      //   loading: true
      // })


      console.log(this.state, '<------ edit submission -----=')
      const data = new FormData();
      let lastphoto = this.state.waHeater.waHeaterImg.length - 1;
      if(typeof this.state.waHeater.waHeaterImg[lastphoto] === "string"){
        console.log("no photo upload");
      } else{
        data.append('waHeaterImg', this.state.waHeater.waHeaterImg[lastphoto], "WaterHeaterImage.jpg");
      }

      
      data.append('waHeatertype', this.state.waHeater.waHeaterType);

      data.append('waHeaterBrand', this.state.waHeater.waHeaterBrand);
      data.append('waHeaterYear', this.state.waHeater.waHeaterYear);
      data.append('waHeaterCondition', this.state.waHeater.waHeaterCondition);
      data.append('waHeaterSingle', this.state.waHeater.waHeaterSingle);
      // data.append('time', this.state.house.time);

      const userId = this.props.userId;
      data.append('userId', userId)

      // const time = new Date();
      // data.append('postingTime', time)
      console.log('put request');
      
      editService.editOneDocument(this.state, 'waHeater', data, userId, this.props.history, this.props.renderNav);
      // axios.put(`${process.env.REACT_APP_API}/api/v1/waHeater/${userId}`, data, {
      //   headers: {
      //     'content-type': 'multipart/form-data',
      //     'Authorization': 'Bearer ' + tokenService.getToken()
      //   }
      // })
      // .then(res => {
      //   console.log(userId);
      //   this.props.history.push(`/mycasa/${userId}` );
      // })
    }

    deleteMyWaHeater = async(id, e) => {
      e.preventDefault()
      const userId = this.props.userId;
      try{
        const response = await fetch(`${process.env.REACT_APP_API}/api/v1/waheater/` + `${userId}`, {
          method: 'DELETE',
          credentials: 'include'
        });

        if(!response.ok){
          throw Error(response.statusText)
        }

      this.props.history.push('/mycasa/' + userId);
      }catch(err){
        alert('Something went wrong. Please try again')
      }

    }

  render(){
    // const userId = sessionStorage.getItem('userId');
    const userId = this.props.userId;

    const waHeaterTypeOptions = ["Select", "Natural Gas Storage", "Natural Gas Tankless", "Electric Storage", "Hybrid Electric Heat Pump", "Electric Heat Pump"];
    const waHeaterYearOptions = ["Select"];
    let today = new Date()
    let cuttentYear = today.getFullYear();

    for(let i = cuttentYear; i >= 1980; i-=1){
        let yr = i;
        waHeaterYearOptions.push(yr.toString())
    }
    const upload = "./../../../upload.svg";
    const sampleWaHeaterImg = "./../../SampleImages/WaHeaterSample.jpg"



    if(this.state.loading){
      return <LoadingPage />
    } else{
    return(

      <div>
        {
          this.props.renderNav ?
          <Nav userId={this.props.userId} handleLogout={this.props.handleLogout}/>
        :
          ""
        }
        <div className="editContainer">
          <div className="editTitle">Water Heater Details</div>
          <form onSubmit={this.handleSubmit}>
            <div className="editBox">
              <div className="helpContainer">
                <span className="inputLabel">PHOTO</span>
                <img data-tip data-for="helpTip" className="help" src="./../../help.svg"/>
                <ReactTooltip id="helpTip" place="bottom" effect="solid" className="tooltipContainer">
                  <img className="sampleImg" src={sampleWaHeaterImg}/>
                  <div className="sampleText">Use your flash if needed to take a photo of your water heater, making sure to capture the serial number.</div>
                </ReactTooltip>
              </div>
              <div className="frames" style={this.state.submitted && (!this.state.preview1 && !this.state.waHeater.waHeaterImg)? {border: '1px dashed #D22E2E'}: {}}>
                <img id="photoOne"
                     className={this.state.preview1 ? "imgAttached" : "placer"}
                     src={this.state.preview1 ? this.state.preview1 :  this.state.waHeater.waHeaterImg}
                     onClick={this.handleClick} />
              </div>
              <input name="photoOne" className="fileUpload" id="input-photoOne" onChange={this.fileSelectHandler} type="file"/>

              <div className="inputContainer">

                <label className="inputLabel" htmlFor="waHeatertype">TYPE OF WATER HEATER*
                  <span className="tipContainer">
                    <img data-tip data-for="waHeatertype" className="help" src="./../../help.svg"/>
                    <ReactTooltip id="waHeatertype" place="bottom" effect="solid" className="tip">
                      <div className="tipText">Email north@housegrades.com if you can’t determine what type of equipment you have.</div>
                    </ReactTooltip>
                  </span>
                </label>
                <select className={!this.state.waHeater.valid.waHeaterType && this.state.submitted ? "selectInput notValid" : "selectInput"} name="waHeaterType" id="waHeaterType" type="text" onChange={this.handleInput} value={this.state.waHeater.waHeaterType}>
                  {waHeaterTypeOptions.map(waHeaterTypeOption => {
                      return <option value={waHeaterTypeOption} key={waHeaterTypeOption} >{waHeaterTypeOption}</option>
                  })}
                </select>
                {!this.state.waHeater.valid.waHeaterType && this.state.submitted ? <ValidationMessage text={'Please enter a water heater type.'}/> : ''}
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="waHeaterBrand">BRAND</label>
                <input name="waHeaterBrand" id="waHeaterBrand" type="text" onChange={this.handleInput} value={this.state.waHeater.waHeaterBrand} />
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="waHeaterYear">YEAR OF MANUFACTURE*
                  <span className="tipContainer">
                    <img data-tip data-for="waHeaterYear" className="help" src="./../../help.svg"/>
                    <ReactTooltip id="waHeaterYear" place="bottom" effect="solid" className="tip">
                      <div className="tipText">Sometimes the year can be found on the device. If not, and you can read the serial number, you can look it up by visiting the website https://www.building-center.org/. For help, please email north@housegrades.com.</div>
                    </ReactTooltip>
                  </span>
                </label>
                <select className={!this.state.waHeater.valid.waHeaterYear && this.state.submitted ? "selectInput notValid" : "selectInput"} name="waHeaterYear" id="waHeaterYear" type="text" onChange={this.handleInput} value={this.state.waHeater.waHeaterYear}>
                  {waHeaterYearOptions.map(waHeaterYearOption => {
                      return <option value={waHeaterYearOption} key={waHeaterYearOption} >{waHeaterYearOption}</option>
                  })}
                </select>
                {!this.state.waHeater.valid.waHeaterYear && this.state.submitted ? <ValidationMessage text={'Please enter a year of manufacture.'}/> : ''}
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="waHeaterCondition">IS THE SYSTEM WORKING WELL?*
                  <span className="tipContainer">
                    <img data-tip data-for="waHeaterCondition" className="help" src="./../../help.svg"/>
                    <ReactTooltip id="waHeaterCondition" place="bottom" effect="solid" className="tip">
                      <div className="tipText">If your water heater produces enough hot water for your needs, it is working well. If there is not enough hot water, the water is rusty, there are leaks or you hear rumbling, it is not working well.</div>
                    </ReactTooltip>
                  </span>
                </label>
                <div id="waHeaterCondition" >
                  <input name="waHeaterCondition" type="radio" checked={this.state.waHeater.waHeaterCondition === "Yes"} value="Yes" onChange={this.handleInput}/><span className="radioNext">YES</span>
                  <input name="waHeaterCondition" className="radioInput-right" type="radio" checked={this.state.waHeater.waHeaterCondition === "No"} value="No" onChange={this.handleInput}/><span className="radioNext">NO</span>
                </div>
                {!this.state.waHeater.waHeaterCondition && this.state.submitted ? <ValidationMessage text={'Please select Yes or No.'}/> : ''}
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="waHeaterSingle">IS THERE MORE THAN ONE WATER HEATER?</label>
                <div id="waHeaterSingle" >
                  <input name="waHeaterSingle" type="radio" checked={this.state.waHeater.waHeaterSingle === "Yes"} value="Yes" onChange={this.handleInput}/><span className="radioNext">YES</span>
                  <input name="waHeaterSingle" className="radioInput-right" type="radio" checked={this.state.waHeater.waHeaterSingle === "No"} value="No" onChange={this.handleInput}/><span className="radioNext">NO</span>
                </div>
              </div>
              <div className="inputContainer">
                <button type="submit" className="btn">SAVE</button>
                <button className="deleteBtn" onClick={this.deleteMyWaHeater.bind(null, userId)}>DELETE</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
  }
}

export default withRouter(EditWaHeater)
