import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import './EditModal.css';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

import EditHouse from "../../../../MyCasaComponents/HouseContainer/EditHouse";
import EditRoof from "../../../../MyCasaComponents/RoofContainer/EditRoof";
import EditAttic from "../../../../MyCasaComponents/AtticContainer/EditAttic";
import EditWaHeater from "../../../../MyCasaComponents/WaHeaterContainer/EditWaHeater";
import EditSpHeater from "../../../../MyCasaComponents/SpHeaterContainer/EditSpHeater";
import EditUtility from "../../../../MyCasaComponents/UtilityContainer/EditUtility";


export default function EditModal({data, type, history}) {
    const [show, setShow] = useState(false);
  
    const renderBackdrop = (props) => <div className="edit-backdrop" />;
    function hideModal(){
        console.log("modal hide firing")
        setShow(false)
    }

    // <EditRoof user={user} userId={user ? user._id : ''} handleLogout={handleLogout} history={history}/>


    function renderEditPages(data, history, type){
        switch(type){
            case 'house':
                return <EditHouse  userId={data.userId} history={history} renderNav={false} />;
            case 'attic':
                return <EditAttic  userId={data.userId} history={history} renderNav={false} />;
            case 'roof':
                return <EditRoof  userId={data.userId} history={history} renderNav={false} />;
            case 'waHeater':
                return <EditWaHeater  userId={data.userId} history={history} renderNav={false} />;
            case 'spHeater':
                return <EditSpHeater  userId={data.userId} history={history} renderNav={false} />;
            case 'utility':
                return <EditUtility  userId={data.userId} history={history} renderNav={false} />
            default:
                return <p>Error.</p>
        }
    }

    return (
      <div className="modal-example">
        <Link
          
          to="#"
          onClick={() => setShow(true)}
        >
          Edit
        </Link>
        
  
        {/* <Modal
          scrollable={true}
          backdrop={true}
          className="edit-modal"
          show={show}
          onHide={hideModal}
        //   renderBackdrop={renderBackdrop}
        //   aria-labelledby="modal-label"
        >
          <div className="edit-window">
            
            {data.userId ? renderEditPages(data, history, type) : ""}
            
            
            
            <button onClick={hideModal}>Close</button>
          </div>
        </Modal> */}

        <Modal show={show} onHide={hideModal} size="lg">
        {/* <Modal.Header >
          
        </Modal.Header> */}
        <Modal.Body>
        <div className="edit-close" onClick={hideModal}><img style={{width: '14px'}} src="../SVG/Close.svg"/></div>
        <div className="edit-window">
            
            {data.userId ? renderEditPages(data, history, type) : ""}
            
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button style={{width: '10rem'}} variant="secondary"  onClick={hideModal}>
            Close
          </Button>
          
        </Modal.Footer> */}
      </Modal>

      </div>
    );
  }