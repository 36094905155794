import React from 'react';
import './Loading.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export default function LoadingPage(props){
    return(
        <Container>
            <Row style={props.dataPage ? {marginTop: '16vw'} : {marginTop: '30vw'}}>
                <Col md={{span: 4, offset: 4}}>
                    <div className="loading-img-container">
                        <img className="pulse-logo" src={process.env.PUBLIC_URL + "/logo.png"} />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}