import React, { useState } from "react";
import "./LandingPageMid.scss";
import { ElLink } from "../../Shared/Button/Button.jsx";

export default class LandingPageMid extends React.Component {
  constructor() {
    super();
    this.mobileView = window.matchMedia("(max-width: 700px)").matches;
    this.info = [
      {
        imgSrc: "./LandingPage/LandingPageMid1.png",
        orderName: "1. Photos",
        text: "Use your smart phone to take and upload photos of key factors that impact energy performance",
      },
      {
        imgSrc: "./LandingPage/LandingPageMid2.png",
        orderName: "2. Grades",
        text: 'Receive analysis of your house with grades on building efficiency and electrification ("BEE")',
      },
      {
        imgSrc: "./LandingPage/LandingPageMid3.png",
        orderName: "3. Projects",
        text: "Plan for upgrades, before emergencies, and stay up-to-date on incentives and rebates",
      },
    ];
  }

  render() {
    return (
      <div className="background pb-4">
        <div className="container ">
          <div className="sub-mid-title">
            <h2 id="how-it-works" className="text-center sub-mid-title top-heading">How It Works</h2>
          </div>

          {this.info.map((el, i) => (
            <div
              key={i}
              className="row mt-5 mb-5 justify-content-center  align-items-center"
            >
              <div
                className={
                  (this.mobileView ? "d-flex justify-content-center pt-2 pb-2" : " col-md-1 offset-md-1 col-sm-12")

                }
              >
                <img src={el.imgSrc} alt="Icon" className={(this.mobileView ? "justify-content-center" : " ") + "m-auto"} />
              </div>
              <h3
                className={
                  this.mobileView
                    ? " text-center "
                    : "" + "  col-md-2 custom-big-text  col-sm-12"
                }
              >
                {el.orderName}
              </h3>
              <div
                className={
                  this.mobileView ? "text-center " : "" + "   col-md col-sm-12 "
                }
              >
                <p className="el-text">{el.text}</p>
              </div>
            </div>
          ))}
          {/* <div className="row justify-content-center">
            {!this.mobileView ? (
              <ElLink link="/mycasa" text="Get Started" customWidht={"35%"} />
            ) : (
              <></>
            )}
          </div> */}
        </div>
      </div>
    );
  }
}
