import React, { Component } from 'react';
// import '../MyCasaDashboard/MyCasaDashboard.scss';
import './MyCasaContainer.scss';
import MyCasaDashboard from '../MyCasaDashboard';
import MyProjectComponent from '../../MyProjectComponents/';
import Nav from './../../Nav'
// import './HouseDetailContainer.css';

class MyCasaContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      dataPage: true
    }
  }

  componentDidMount(){
    this.setState({dataPage: true});

  };


  handleClick = () => {

    this.setState({
      dataPage: !this.state.dataPage
    })
  }





  render(){

    return(
      <>
      <Nav handleLogout={this.props.handleLogout} userId={this.props.userId}/>
        <div className="myCasaContainer">
          <div className="titleContainer myCasaTitle">
            <div className="title h2">My House</div>
            <div className="subtitle h4 myCasaSubtitle">Add photos to get grades on building efficiency and electrication with upgrade recommendations.</div>
          </div>
      <div className="toggle-container" onClick={this.handleClick}>
          <div className="data-toggle" id={this.state.dataPage ? 'active-toggle' : 'inactive-toggle'}>
            <span className="toggle-text">Photos</span>
          </div>
          <div className="analysis-toggle" id={!this.state.dataPage ? 'active-toggle' : 'inactive-toggle'}>
          <span className="toggle-text">Grades</span>
          </div>
      </div>

      {this.state.dataPage ?
      <MyCasaDashboard user={this.props.user} user_id={this.props.user_id} />
      :
      <MyProjectComponent user={this.props.user} userId={this.props.user._id} renderNav={false}/>
      }
      </div>
      </>
    )
  }
}
export default MyCasaContainer;
