function handleInput(e, objectName, validationFields) {
    console.log(e.target.name + ' <---- name')
    console.log(e.target.value + ' <---- value')
    let updatedChange = 
      {...this.state[objectName]}
    ;
    console.log(updatedChange, "updatedChange<------------")
    // validation
    if((validationFields.includes(e.target.name)) && (e.target.value !== '')){
      updatedChange[e.target.name] = e.target.value;
      
      if(updatedChange[e.target.name] === 'Select'){
        console.log('Select Firing<-------');
        updatedChange.valid= {
          ...this.state[objectName].valid,
          [e.target.name]: false
        };
      }
      else {
        updatedChange.valid= {
          ...this.state[objectName].valid,
          [e.target.name]: true
        };
      }
     
      this.setState({
        ...this.state,
        [objectName]: updatedChange
      });
    } 
    else {
      updatedChange[e.target.name] = e.target.value;
      this.setState({
        ...this.state,
        [objectName]: updatedChange
      });
    }
      
      
      
    }

    
    
    
    export {
      
      handleInput
    };