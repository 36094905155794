import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import Moment from 'react-moment';
import Nav from '../../../Nav';
import LoadingPage from '../../../Shared/Loading/Loading';
import ReactTooltip from "react-tooltip";
import * as PhotoService from '../../../utils/photoUploadService';
import editService from '../../../utils/editService';
import ValidationMessage from '../../../Shared/ValidationMessage/ValidationMessage';

class EditRoof extends Component {
  constructor(props){
    super(props);
    this.state = {
      roof : {
        roofImg: [],
        exterior : '',
        roofColor: '',
        pvSystem: '',
        panels: '',
        dcCapacity: '',
        userId: '',
      },
      preview: null,
      selectedFile : null,
      loading: true,
      submitted: false
    }
  };

  componentDidMount(){
    this.getHouseInfo();
    this.setState({
      ...this.state,
      loading: false
    })
  };

  getHouseInfo = async() => {
    const userId = this.props.userId;

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/v1/users/${userId}`,  {
        credentials: 'include'
      });

      if(!response.ok){
        throw Error(response.statusText)
      };

      const userParsed = await response.json();

      this.setState({
        ...this.state,
          roof: {
            roofImg: userParsed.roof.roofImg,
            exterior: userParsed.roof.exterior,
            roofColor: userParsed.roof.roofColor,
            pvSystem: userParsed.roof.pvSystem,
            panels: userParsed.roof.panels,
            dcCapacity: userParsed.roof.dcCapacity
          }
      });
    } catch(err) {
      return err
    };
  };

  handleInput = e => {
    const updatedChange = {
      ...this.state.roof
    };

    updatedChange[e.target.name] = e.target.value;

    this.setState({
      roof: updatedChange
    });
  };

  handleClick = e => {
    var frame = document.getElementById(`input-${e.target.id}`);
    frame.click();
  };

  handleSubmit = e => {
    e.preventDefault();

    if(this.state.roof.pvSystem && this.state.preview1){

    const updatedRoof = {
      ...this.state.roof
    };

    this.editRoof(updatedRoof);

    this.setState({
      roof : {
        roofImg: null,
        exterior : '',
        roofColor: '',
        pvSystem: '',
        panels: '',
        dcCapacity: '',
        userId: '',
      }
    });
  } else {
    this.setState({
      ...this.state,
      submitted: true,
    })
  }
  };

  // fileSelectHandler = e => {
  //   var file1;

  //   switch (e.target.id) {
  //     case 'input-photoOne':
  //         file1 = e.target.files[0];
  //       break;

  //     default:
  //       console.log('error');
  //       return 0;
  //   };

  //   var reader1 = new FileReader();
  //   var url1 = typeof file1 !== 'undefined'? reader1.readAsDataURL(file1):null;

  //   reader1.onloadend = function(e){
  //     this.setState({
  //         preview1: [reader1.result || null],
  //       })
  //   }.bind(this);

  //   this.setState({
  //     roof: {
  //       ...this.state.roof,
  //       roofImg: e.target.files[0]
  //     }
  //   });
  // };

  fileSelectHandler = async function (e) {
    let objectName = "roof";
    await PhotoService.handleUploadFile.call(this, e, objectName);

  }.bind(this);

  handleEditFormInput = e => {
    this.setState({
      roof: {
        ...this.state.roof,
        [e.target.name]:  e.target.value
      }
    });
  };

  editRoof = async(e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      loading: true
    })
    const data = new FormData();
    console.log(this.state.roofImg, "roofImg from edit <---------")

  let lastphoto = this.state.roof.roofImg.length - 1;
    if(typeof this.state.roof.roofImg[lastphoto] === "string"){
      console.log("no photo upload");
    } else{
      data.append('roofImg', this.state.roof.roofImg[lastphoto], "roofImage.jpg");
    }
    data.append('exterior', this.state.roof.exterior);
    data.append('roofColor', this.state.roof.roofColor);
    data.append('pvSystem', this.state.roof.pvSystem);
    data.append('panels', this.state.roof.panels);
    data.append('dcCapacity', this.state.roof.dcCapacity);
    // data.append('time', this.state.house.time);

    const userId = this.props.userId;

    data.append('userId', userId);

    editService.editOneDocument(this.state, 'roof', data, userId, this.props.history, this.props.renderNav);


  };

  deleteMyRoof = async(id, e) => {
    e.preventDefault();

    const userId = this.props.userId;

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/v1/roof/` + `${userId}`, {
        method: 'DELETE',
        credentials: 'include'
      });

      if(!response.ok){
        throw Error(response.statusText)
      };

      this.props.history.push('/mycasa/' + userId);

    } catch(err) {
      alert('Something went wrong. Please try again')
    };
  };

  render(){
    const userId = this.props.userId;

    const extriorOptions = ["Select", "Shingles or Metal", "Wood Shakes", "Clay Tile", "Concrete Tile", "Tar & Gravel"];
    const colorOptions = ["Select", "Less than 15 years", "15 to 25 years", "More than 25 years", "Not Sure"];
    const roofImgState = `${process.env.REACT_APP_API}/` + this.state.roof.roofImg;
    const upload = "./../../../upload.svg";
    const sampleRoofImg = "./../../SampleImages/RoofSample.jpg";

    if(this.state.loading){
      return <LoadingPage />
    }else {

    return(
      <div>
        {
          this.props.renderNav ?
          <Nav userId={this.props.userId} handleLogout={this.props.handleLogout}/>
        :
          ""
        }
        <div className="editContainer">
        <div className="editTitle">Roof Details</div>
          <form onSubmit={this.editRoof}>
            <div className="editBox">
              <div className="helpContainer">
                <span className="inputLabel">PHOTO</span>
                <img data-tip data-for="helpTip" className="help" src="./../../help.svg"/>
                <ReactTooltip id="helpTip" place="bottom" effect="solid" className="tooltipContainer">
                  <img className="sampleImg" src={sampleRoofImg}/>
                  <div className="sampleText">Take a photo of your roof, capturing the roofing material and any Solar (PV) panels.</div>
                </ReactTooltip>
              </div>
              <div className="frames">
                <img id="photoOne"
                     className={this.state.preview1 ? "imgAttached" : "placer"}
                     src={this.state.preview1 ? this.state.preview1 :  this.state.roof.roofImg}
                     onClick={this.handleClick} />
              </div>
              <input name="photoOne" className="fileUpload" id="input-photoOne" onChange={this.fileSelectHandler} type="file" />

                <div className="inputContainer">
                  <label className="inputLabel" htmlFor="exterior">EXTERIOR FINISH</label>
                  <select className="selectInput" name="exterior" id="exterior" type="text" onChange={this.handleEditFormInput} value={this.state.roof.exterior}>
                    {extriorOptions.map(extriorOption => {
                        return <option value={extriorOption} key={extriorOption} >{extriorOption}</option>
                    })}
                  </select>
                </div>
                <div className="inputContainer">
                  <label className="inputLabel" htmlFor="roofColor">AGE</label>
                  <select className="selectInput" name="roofColor" id="roofColor" type="text" onChange={this.handleEditFormInput} value={this.state.roof.roofColor}>
                    {colorOptions.map(colorOption => {
                        return <option value={colorOption} key={colorOption} >{colorOption}</option>
                    })}
                  </select>
                </div>
                <div className="inputContainer">
                  <label className="inputLabel" htmlFor="pvSystem">IS THERE A SOLAR PV SYSTEM INSTALLED?*</label>
                  <div id="pvSystem" >
                    <input name="pvSystem" type="radio" checked={this.state.roof.pvSystem === "Yes"} value="Yes" onChange={this.handleEditFormInput}/><span className="radioNext">YES</span>
                    <input name="pvSystem" className="radioInput-right" type="radio" checked={this.state.roof.pvSystem === "No"} value="No" onChange={this.handleEditFormInput}/><span className="radioNext">NO</span>
                  </div>
                </div>
                {!this.state.roof.pvSystem && this.state.submitted ? <ValidationMessage text={'Please select Yes or No.'}/> : ''}
                <div className="inputContainer">
                  <label className="inputLabel" htmlFor="panels">NUMBER OF PANELS</label>
                  <input name="panels" id="panels" type="number" onChange={this.handleEditFormInput} value={this.state.roof.panels} />
                </div>
                <div className="inputContainer">
                  <label className="inputLabel" htmlFor="dcCapacity">SIZE (KW)</label>
                  <input name="dcCapacity" id="dcCapacity" type="number" onChange={this.handleEditFormInput} value={this.state.roof.dcCapacity} />
                </div>
                <div className="inputContainer">
                  <button type="submit" className="btn">SAVE</button>
                  {/* <button className="deleteBtn" onClick={this.deleteMyRoof.bind(null, userId)}>DELETE</button> */}
                  <button className="deleteBtn" onClick={this.deleteMyRoof.bind(null, userId)}>DELETE</button>
                </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  };
};

export default withRouter(EditRoof);
