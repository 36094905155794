import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios'
// import Moment from 'react-moment';
import Nav from '../../../Nav';
import LoadingPage from '../../../Shared/Loading/Loading';
import ReactTooltip from "react-tooltip";
import tokenService from '../../../utils/tokenService';
import * as PhotoService from '../../../utils/photoUploadService';
import PhotoUploadLink from '../../../Shared/PhotoUploadLink/PhotoUploadLink';
import ValidationMessage from '../../../Shared/ValidationMessage/ValidationMessage';
import * as FormService from '../../../utils/formService';

class CreateWaHeater extends Component {
  constructor(props){
    super(props)
    this.state = {
      waHeater : {
        waHeaterImg: [],
        waHeaterType : '',
        waHeaterBrand: '',
        waHeaterYear: '',
        waHeaterCondition: '',
        waHeaterSingle: '',
        userId: '',
        valid: {
          waHeaterType: false,
          waHeaterYear: false,
        }
      },
      preview: null,
      selectedFile : null,
      submitted: false,
      loading: false
    }
  }

  handleInput = (e) => {
    FormService.handleInput.call(this, e, 'waHeater', ['waHeaterType', 'waHeaterYear']);

  
  }

  handleClick = (e) => {
    var frame = document.getElementById(`input-${e.target.id}`)
    frame.click();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.waHeater, "<--------submit");
    if(this.state.waHeater.valid.waHeaterType && this.state.waHeater.waHeaterCondition && this.state.waHeater.valid.waHeaterYear && this.state.preview1){

    this.setState({
      ...this.state,
      loading: true
    })
    const updatedWaHeater = {
      ...this.state.waHeater
    }

    this.addWaHeater(updatedWaHeater)

    this.setState({
      waHeater : {
        waHeaterImg: [],
        waHeaterType : '',
        waHeaterBrand: '',
        waHeaterYear: '',
        waHeaterCondition: '',
        waHeaterSingle: '',
        userId: '',
      },
    })
  } else {
    this.setState({
      ...this.state,
      submitted: true,
    })
  }
}

    fileSelectHandler = async function (e) {
      let objectName = "waHeater";
      await PhotoService.handleUploadFile.call(this, e, objectName);

    }.bind(this);

    addWaHeater = (updatedWaHeater) => {
      const data = new FormData();
      // file select process requires that Img be in an array w/ the most recent last
      let lastphoto = this.state.waHeater.waHeaterImg.length - 1;
      data.append('waHeaterImg', this.state.waHeater.waHeaterImg[lastphoto], "waterHeaterImage.jpg");
      data.append('waHeatertype', this.state.waHeater.waHeaterType);
      data.append('waHeaterBrand', this.state.waHeater.waHeaterBrand);
      data.append('waHeaterYear', this.state.waHeater.waHeaterYear);
      data.append('waHeaterCondition', this.state.waHeater.waHeaterCondition);
      data.append('waHeaterSingle', this.state.waHeater.waHeaterSingle);
      // data.append('time', this.state.house.time);

      let userId = this.props.user._id;
      data.append('userId', userId)

      const time = new Date();
      data.append('postingTime', time)

      axios.post(`${process.env.REACT_APP_API}/api/v1/waHeater`, data, {
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': 'Bearer ' + tokenService.getToken()
        }
      })
      .then(res => {
        if (res.status === 200) {
          this.props.history.push('/mycasa/' +  this.props.user._id);
        }
      })
    };

  render(){

    //redirects user to mycasa page once form data is saved to db
    // if (this.state.submitted) {
    //   this.props.history.push('/mycasa/' +  this.props.user._id);
    // }

    const waHeaterTypeOptions = ["Select", "Natural Gas Storage", "Natural Gas Tankless", "Electric Storage", "Hybrid Electric Heat Pump", "Electric Heat Pump"];
    const waHeaterYearOptions = ["Select"];

    let today = new Date()
    let cuttentYear = today.getFullYear();

    for(let i = cuttentYear; i >= 1980; i-=1){
      let yr = i;
      waHeaterYearOptions.push(i.toString())
    }

    const upload = "./../../../upload.svg";
    const sampleWaHeaterImg = "./../../SampleImages/WaHeaterSample.jpg";

    if(this.state.loading){
      return <LoadingPage />
    }else {

    return(

      <div>
        <Nav userId={this.props.userId} handleLogout={this.props.handleLogout}/>
        <div className="createContainer">
          <div className="createTitle">Water Heater Details</div>
          <form onSubmit={this.handleSubmit}>
            <div className="createBox">
              <div className="helpContainer">
                <span className="inputLabel">PHOTO</span>
                <img data-tip data-for="helpTip" className="help" src="./../../help.svg"/>
                <ReactTooltip id="helpTip" place="bottom" effect="solid" className="tooltipContainer">
                  <img className="sampleImg" src={sampleWaHeaterImg}/>
                  <div className="sampleText">Use your flash if needed to take a photo of your water heater, making sure to capture the serial number.</div>
                </ReactTooltip>
              </div>
              <div className="frames" style={this.state.submitted && !this.state.preview1 ? {border: '1px dashed #D22E2E'}: {}}>
              {this.state.preview1 ? <img className="imgAttached" id="photoOne" src={this.state.preview1}  onClick={this.handleClick } /> 
                : <div id="photoOne" onClick={this.handleClick }><PhotoUploadLink />
                {this.state.submitted ? <ValidationMessage text={'Please upload a photo.'}/> : ''}
                </div>}
                {/* <img className={this.state.preview1 ? "imgAttached" : "placer"} id="photoOne" src={this.state.preview1 ? this.state.preview1 :  upload} onClick={this.handleClick } /> */}
                <input name="photoOne" className="fileUpload" id="input-photoOne" onChange={this.fileSelectHandler} type="file"/>
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="waHeaterType">TYPE OF WATER HEATER *
                  <span className="tipContainer">

                    <img data-tip data-for="waHeatertype" className="help" src="./../../help.svg"/>
                    <ReactTooltip id="waHeatertype" place="bottom" effect="solid" className="tip">
                      <div className="tipText">Email north@housegrades.com if you can’t determine what type of equipment you have.</div>

                    </ReactTooltip>
                  </span>
                </label>
                <select className={!this.state.waHeater.valid.waHeaterType && this.state.submitted ? "selectInput notValid" : "selectInput"} name="waHeaterType" id="waHeaterType" type="text" onChange={this.handleInput} value={this.state.waHeater.waHeaterType}>
                  {waHeaterTypeOptions.map(waHeaterTypeOption => {
                      return <option value={waHeaterTypeOption} key={waHeaterTypeOption} >{waHeaterTypeOption}</option>
                  })}
                </select>
                {!this.state.waHeater.valid.waHeaterType && this.state.submitted ? <ValidationMessage text={'Please enter a water heater type.'}/> : ''}
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="waHeaterBrand">BRAND</label>
                <input name="waHeaterBrand" id="waHeaterBrand" type="text" onChange={this.handleInput} value={this.state.waHeater.waHeaterBrand} />
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="waHeaterYear">YEAR OF MANUFACTURE*
                  <span className="tipContainer">
                    <img data-tip data-for="waHeaterYear" className="help" src="./../../help.svg"/>
                    <ReactTooltip id="waHeaterYear" place="bottom" effect="solid" className="tip">
                      <div className="tipText">Sometimes the year can be found on the device. If not, and you can read the serial number, you can look it up by visiting the website https://www.building-center.org/. For help, please email north@housegrades.com.</div>
                    </ReactTooltip>
                  </span>
                </label>
                <select className={!this.state.waHeater.valid.waHeaterYear && this.state.submitted ? "selectInput notValid" : "selectInput"} name="waHeaterYear" id="waHeaterYear" type="text" onChange={this.handleInput} value={this.state.waHeater.waHeaterYear}>
                  {waHeaterYearOptions.map(waHeaterYearOption => {
                      return <option value={waHeaterYearOption} key={waHeaterYearOption} >{waHeaterYearOption}</option>
                  })}
                </select>
                {!this.state.waHeater.valid.waHeaterYear && this.state.submitted ? <ValidationMessage text={'Please enter a year of manufacture.'}/> : ''}
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="waHeaterCondition">IS THE SYSTEM WORKING WELL?*
                  <span className="tipContainer">
                    <img data-tip data-for="waHeaterCondition" className="help" src="./../../help.svg"/>
                    <ReactTooltip id="waHeaterCondition" place="bottom" effect="solid" className="tip">
                      <div className="tipText">If your water heater produces enough hot water for your needs, it is working well. If there is not enough hot water, the water is rusty, there are leaks or you hear rumbling, it is not working well.</div>
                    </ReactTooltip>
                  </span>
                </label>
                <div id="waHeaterCondition" >
                  <input name="waHeaterCondition" type="radio" checked={this.state.waHeater.waHeaterCondition === "Yes"} value="Yes" onChange={this.handleInput}/><span className="radioNext">YES</span>
                  <input className="radioInput-right" name="waHeaterCondition" type="radio" checked={this.state.waHeater.waHeaterCondition === "No"} value="No" onChange={this.handleInput}/><span className="radioNext">NO</span>
                </div>
              </div>
              {!this.state.waHeater.waHeaterCondition && this.state.submitted ? <ValidationMessage text={'Please select Yes or No.'}/> : ''}
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="waHeaterSingle">IS THERE MORE THAN ONE WATER HEATER?</label>
                <div id="waHeaterSingle" >
                  <input name="waHeaterSingle" type="radio" checked={this.state.waHeater.waHeaterSingle === "Yes"} value="Yes" onChange={this.handleInput}/><span className="radioNext">YES</span>
                  <input className="radioInput-right" name="waHeaterSingle" type="radio" checked={this.state.waHeater.waHeaterSingle === "No"} value="No" onChange={this.handleInput}/><span className="radioNext">NO</span>
                </div>
              </div>
              <div className="inputContainer">
                <button type="submit" className="btn">SAVE</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
  }
}

export default withRouter(CreateWaHeater)
