import React from 'react';
import './Pointer.css';

export default function Pointer({page}){
    return(
        <>
        {page === "index" ?
            <div className="pointer-div" >
                <img className="pointer-arrow" style={{width: '14px'}} src="../SVG/subNavPointer.svg"/>
                <img className="pointer-line" style={{width: '80px', height: '3px'}} src="../SVG/subNavLine.svg"/>

            </div>
        :

            <div className="pointer-div-show" >
                <img className="pointer-arrow" style={{width: '14px'}} src="../SVG/subNavPointer.svg"/>
                <img className="pointer-line" style={{width: '80px', height: '3px'}} src="../SVG/subNavLine.svg"/>

            </div>
    
        }
        </>
    )
}