import React from 'react';
import './AddressLabelCard.css'

export default function AddressLabelCard({address}){
    console.log("Address firing <----")
    return(
        <>
        {
        address.user ?
        <div>
            <h2>{address.user.firstName + ' ' + address.user.lastName}</h2>
            <p>{address.house.address + ' ' + address.house.city + ' ' + address.house.state + ' ' + address.house.zipcode}</p>
        </div>
        :
        <div>Loading</div>
        }   
        
            
            
        </>
        
    )
}