import React from "react";
import ElectrifyLayout from "./ElectrifyLayout";
import './ElectrifyComponents.scss'

const HotWater = (props) => {
  return (

        <ElectrifyLayout
          userId={props.userId} handleLogout={props.handleLogout}
          title="Hot Water"
          electrifyIcon="../Electrify/HotWater.svg"
          includedInRating="Impacts Electrification Grade"
          p1="Approximately 18-30% of residential home energy usage is for hot
              water. Water can be heated with gas or electricity."
          p2="Most water heaters use gas, because it has been, until recently,
              the least expensive fuel, however, new heat pump technology water
              heaters are 3-8x more efficient than gas. They are also safer."
          p3="The advantage of heat pump water heaters is that they can be
              powered by electricity generated from renewable sources such as
              rooftop solar. Switching to a heat pump water heater is essential
              to lower your carbon footprint."
          p4="The price for a heat pump water heater starts at $1500, before
          rebates and incentives, and the 
          installation cost varies depending on the house."
    />

  );
};

export default HotWater;
