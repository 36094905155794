import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios'
// import Moment from 'react-moment';
import Nav from '../../../Nav';
import LoadingPage from '../../../Shared/Loading/Loading';
import ReactTooltip from "react-tooltip";
import tokenService from '../../../utils/tokenService';
import * as PhotoService from '../../../utils/photoUploadService';
import PhotoUploadLink from '../../../Shared/PhotoUploadLink/PhotoUploadLink';
import ValidationMessage from '../../../Shared/ValidationMessage/ValidationMessage';


class HouseContainer extends Component {
  constructor(props){
    super(props);
    this.state = {
      house : {
        houseImg:[],
        address : '',
        city:'',
        state: '',
        zipcode: '',
        houseYear: '',
        houseSqft: '',
      },
      preview: null,
      selectedFile : null,
      submitted: false,
      loading: false
    }
  };

  handleInput = e => {
    const updatedChange = {
      ...this.state.house
    };

    updatedChange[e.target.name] = e.target.value;

    this.setState({
      house: updatedChange
    });
  };

  handleClick = e => {
    var frame = document.getElementById(`input-${e.target.id}`);
    frame.click();
  };

  handleSubmit = e => {
    e.preventDefault();
    if(this.state.house.address &&
      this.state.house.city &&
      this.state.house.state &&
      this.state.house.zipcode &&
      this.state.preview1){

    this.setState({
      ...this.state,
      loading: true
    })
    const updatedHouse = {
      ...this.state.house
    };

    this.addHouse(updatedHouse);

    this.setState({
      house : {
        houseImg: null,
        address : '',
        city: '',
        state: '',
        zipcode: '',
        houseYear: '',
        houseSqft: '',
        userId: '',
      },
    })
  } else {
    this.setState({
      ...this.state,
      submitted: true,
    })
  }
  };

  // fileSelectHandler = e => {
  //   var file1

  //   switch (e.target.id) {
  //     case 'input-photoOne':
  //         file1 = e.target.files[0];
  //       break;

  //     default:
  //       console.log('error');
  //       return 0;
  //   };

  //   var reader1 = new FileReader();
  //   var url1 = typeof file1 !== 'undefined'? reader1.readAsDataURL(file1):null;

  //   reader1.onloadend = function(e){
  //     this.setState({
  //         preview1: [reader1.result || null],
  //       })
  //     }.bind(this);

  //   this.setState({
  //     house: {
  //       ...this.state.house,
  //       houseImg: [...this.state.house.houseImg, e.target.files[0]]
  //     }
  //   });
  // };

  fileSelectHandler = async function (e) {
    let objectName = "house";
    await PhotoService.handleUploadFile.call(this, e, objectName);

  }.bind(this);


  addHouse = async(updatedHouse) => {
      const data = new FormData();
      let lastphoto = this.state.house.houseImg.length - 1;
      
      data.append('houseImg', this.state.house.houseImg[lastphoto], "houseImage.jpg");
      

      data.append('address', this.state.house.address);
      data.append('city', this.state.house.city);
      data.append('state', this.state.house.state);
      data.append('zipcode', this.state.house.zipcode);
      data.append('houseYear', this.state.house.houseYear);
      data.append('houseSqft', this.state.house.houseSqft);
      // data.append('time', this.state.house.time);

      let userId = this.props.user._id;
      data.append('userId', userId)

      const time = new Date();
      data.append('postingTime', time)

      axios.post(`${process.env.REACT_APP_API}/api/v1/house`, data, {
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': 'Bearer ' + tokenService.getToken()
        }
      })
      .then(res => {
        if (res.status === 200) {
          this.props.history.push('/mycasa/' +  this.props.user._id); 
        }
      })        
    };

  render() {
    //redirects user to mycasa page once form data is saved to db
    // if (this.state.submitted) {
    //   this.props.history.push('/mycasa/' +  this.props.user._id);
    // }

    //need to move json file
    let statesObj = [
    {
        "name": "None",
        "abbreviation": ""
    },
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
  ];

    let houseYearOptions = ["Select"];
    let today = new Date();
    let cuttentYear = today.getFullYear();

    for(let i = cuttentYear; i >= 1900; i-=1){
        houseYearOptions.push(i)
    };

    let helpMark = "./../../../help.svg";
    let upload = "./../../../upload.svg";
    let sampleHouseImg = "./../../SampleImages/houseSample.jpg";

    if(this.state.loading){
      return <LoadingPage />
    }else {

     return (

      <div>
        <Nav userId={this.props.userId} handleLogout={this.props.handleLogout}/>
        <div className="createContainer">
          <div className="createTitle">House Details</div>
          <form onSubmit={this.handleSubmit}>
            <div className="createBox">
              <div className="helpContainer">
                <span className="inputLabel">PHOTO</span>
                <img data-tip data-for="helpTip" className="help" src={helpMark}/>
                <ReactTooltip id="helpTip" place="bottom" effect="solid" className="tooltipContainer">
                  <img className="sampleImg" src={sampleHouseImg}/>
                  <div className="sampleText">Take a photo of the front of your house.</div>
                </ReactTooltip>

              </div>
              <div className="frames" style={this.state.submitted && !this.state.preview1 ? {border: '1px dashed #D22E2E'}: {}}>
              {this.state.preview1 ? <img className="imgAttached" id="photoOne" src={this.state.preview1}  onClick={this.handleClick } /> 
                : <div id="photoOne" onClick={this.handleClick }><PhotoUploadLink />
                {this.state.submitted ? <ValidationMessage text={'Please upload a photo.'}/> : ''}
                </div>}
                {/* <img id="photoOne" className={this.state.preview1 ? "imgAttached" : "placer"} src={this.state.preview1 ? this.state.preview1 :  upload} onClick={this.handleClick} /> */}
                <input name="photoOne" className="fileUpload" id="input-photoOne" onChange={this.fileSelectHandler} type="file" />
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="address">ADDRESS *</label>
                <input className={!this.state.house.address && this.state.submitted ? "notValid" : ''} name="address" id="address" type="text" onChange={this.handleInput} value={this.state.house.address}  />
                {!this.state.house.address && this.state.submitted ? <ValidationMessage text={'Please enter an address.'}/> : ''}
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="city">CITY *</label>
                <input className={!this.state.house.city && this.state.submitted ? "notValid" : ''} name="city" id="city" type="text" onChange={this.handleInput} value={this.state.house.city} />
                {!this.state.house.city && this.state.submitted ? <ValidationMessage text={'Please enter a city.'}/> : ''}
              </div>
              <div className="inputRow">
                <div className="inputItem">
                  <label className="inputLabel" htmlFor="state">STATE *</label>
                  <select className={!this.state.house.state && this.state.submitted ? "selectInput notValid" : "selectInput"} name="state" id="state" type="text" onChange={this.handleInput} value={this.state.house.state}>
                    {statesObj.map((st, idx) => {
                        return <option value={st.abbreviation} key={st + idx}>{st.name}</option>
                    })}
                  </select>
                  {!this.state.house.state && this.state.submitted ? <ValidationMessage text={'Please enter a state.'}/> : ''}
                </div>
                <div className="inputItem">
                  <label className="inputLabel" htmlFor="zipcode">ZIP CODE *</label>
                  <input className={!this.state.house.zipcode && this.state.submitted ? "notValid" : ''} name="zipcode" id="zipcode" type="text" type="text" inputMode="numeric"  onChange={this.handleInput} value={this.state.house.zipcode} />
                  {!this.state.house.zipcode && this.state.submitted ? <ValidationMessage text={'Please enter a zip code.'}/> : ''}
                </div>
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="houseYear">YEAR BUILT</label>
                <input name="houseYear" id="houseYear" type="text" onChange={this.handleInput} value={this.state.house.houseYear} />
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="houseSqft">SQUARE FEET</label>
                <input name="houseSqft" id="houseSqft" type="number" onChange={this.handleInput} value={this.state.house.houseSqft}  />
              </div>
              <div className="inputContainer">
                <button type="submit" className="btn">SAVE</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  } 
  };
};

export default withRouter(HouseContainer);
