import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import DataCard from '../../../AdminComponents/ShowPageComponents/DataCard';


export default function Data (props){
   
    const types = ['house',  'roof', 'attic', 'waHeater', 'spHeater', 'utility'];
    
      
    return (
        types.map((type, idx) => {
            
            if(props.address[type]){
                
                return(
                    <>
                    <Row>
                        <Col md={{span: 10, offset: 1}}>
                            <DataCard data={props.address[type]} key={idx} type={type} history={props.history}/>
                        </Col>
                    </Row>
                    </>
                )
            } 
            
        })
    )
}