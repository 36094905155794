import React, { Component } from 'react';

import './ProjectsContainer.scss';

import Nav from '../../Nav';



import ProjectsPage from '../../ProjectsComponents/ProjectsPage';



import * as addressAPI from '../../utils/addressService';

//Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


class ProjectsContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      address: [],
      page: 'Data'
    }
  }
  
  componentDidMount(){
    this.getOneAddress();
  };





    getOneAddress = async() => {
      try{
  
        const user = window.location.pathname.substring(11);
        console.log(user, '<-----=     <-------=    user from api call')
        
        const addressParsed = await addressAPI.getOneAddressFromUserId(user);
        console.log(addressParsed, "address after API call <------")

        this.setState({
          ...this.state,
          address: addressParsed.address
        })
        console.log(this.state.addresses.house, "address <-------");
        }catch(err){
          return err
      }
    }

    

    


  render(){
    

    

    return(
      <>
        <Nav userId={this.props.user ? this.props.user._id : ''} handleLogout={this.props.handleLogout} />
        <div className="myCasaContainer">
          <div className="titleContainer myCasaTitle">
            <div className="title h2">Projects</div>
            <div className="subtitle h4">Plan upgrades at optimal times to maximize savings and prior to emergencies.</div>
          </div>
      
      
        <Container style={{marginTop: '5rem'}}>
        <Row><Col md={{span: 12, offset: 0}}>
                <ProjectsPage address={this.state.address}/>
        </Col></Row>
         
        </Container>
        </div>
      </>
    )
  }
}


export default ProjectsContainer;
