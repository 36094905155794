import React from "react";
import { ElLink, ButtonMailto } from "../../Shared/Button/Button.jsx";
import "./LandingPageLast.scss";

export default class LandingPageLast extends React.Component {
  constructor() {
    super();
    this.state = {
      mobileView: window.matchMedia("(max-width: 700px)").matches,
    };
    this.info = [
      {
        header: "DIY",
        text: " Use your phone to collect the data for your house, usually in 20 minutes ",
        buttonText: "SIGN UP",
        link: "/signup",
      },
      {
        header: "Ask a Realtor  ",
        text: " Energy grades are useful for buying and selling houses",
        buttonText: "COMING SOON",
        btnDisabled: true,
        extraClasses: "custom-disabled",
      },
      {
        header: "Have Us Help",
        text: "HouseGrades is here to help you through every step of the analysis",
        buttonText: "CONTACT US",
        // THIS NEEDS TO BE mailto: north@housegrades.com in a new tab
        link: "/",
      },
    ];
  }

  render() {
    return (
      <div className="background pb-4">
      <div className="sub-last-container">
        <h2 className="get-it text-center pt-3 ">How To Get It</h2>
        <div
          className={
            this.state.mobileView
              ? " "
              : "row  pb-3 " + " justify-content-center"
              // : "row  pb-3 " + " justify-content-around"
          }
        >
          {this.info.map((el, i) => (
            <div
              key={i}
              className={
                this.state.mobileView
                  ? "mt-3 mb-3 pl-3 pr-3 d-flex justify-content-center"
                  : "" + " col-md-3 text-center col-sm-12 "
              }
            >
              <div className={" card align-items-center card-get-it "}>
                <div className="card-body">
                  <h3 className="card-title text-center">{el.header}</h3>
                  <p className="card-text text-center">{el.text}</p>
                </div>
                { i === 2 ?
                  <ButtonMailto label="Contact Us" mailto="mailto:north@housegrades.com" />
                  :
                  <ElLink
                    link={el.link ? el.link : "#"}
                    text={el.buttonText}
                    disabled={el.btnDisabled}
                    customWidht={"55%"}
                    extraClasses={el.extraClasses}
                  />
                }
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
    );
  }
}
