import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './AdminShow.css';

import Nav from '../../../Nav';
import AddressLabelCard from '../../AdminComponents/ShowPageComponents/AddressLabelCard';
import DataCard from '../../AdminComponents/ShowPageComponents/DataCard';
import SubNav from '../../AdminComponents/SubNav';
import Data from './Data';
import MyProjectComponent from '../../../MyProjectComponents';
import MyAccountContainer from "../../../MyAccountComponents/MyAccountContainer";

import ProjectsPage from '../../../ProjectsComponents/ProjectsPage';

import EditModal from '../../AdminComponents/ShowPageComponents/EditModal';

import * as addressAPI from '../../../utils/addressService';

//Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Toggle from '../../../Shared/Toggle/Toggle';
// --------------------------- TO DO ---------------------------------------


// State setup for pagination
            // 1- 50 = page 1, 51 - 100 = page 2, etc 

// Search bootstrap / component libraries for searchbar and scroll bar

class AdiminHome extends Component {
  constructor(props){
    super(props)
    this.state = {
      address: [],
      page: 'Data'
    }
  }
  
  componentDidMount(){
    this.getOneAddress();
  };

    renderPages = () => {
        switch(this.state.page){
            case 'Data':
              return <Data address={this.state.address} history={this.props.history}/>
            case 'Analysis':
                if(this.state.address.user){
                    return (
                          <Row><Col md={{span: 10, offset: 1}}>
                              <MyProjectComponent user={this.state.address.user} userId={this.state.address.user._id} renderNav={false}/>
                          </Col></Row>
                      )
                }else {
                    return <h1>No Analysis Available</h1>
                }
            case 'Projects':
                return (
                <Row><Col md={{span: 10, offset: 1}}>
                <ProjectsPage address={this.state.address}/>
                </Col></Row>
                )
            case 'Account':
                if(this.state.address.user){
                    return <MyAccountContainer user={this.state.address.user} userId={this.state.address.user._id}  renderNav={false}/>
                }else {
                    return <h1>No Account Info Available</h1>
                }
                
            case 'Notes':
                return <h1>notes</h1>
        }
    }



    getOneAddress = async() => {
      try{
  
        const address = window.location.pathname.substring(7);
        
        const addressParsed = await addressAPI.getOneAddress(address);

        this.setState({
          ...this.state,
          address: addressParsed.address
        })
        console.log(this.state.addresses.house, "address <-------");
        }catch(err){
          return err
      }
    }

    validate = async(validatedAddress) => {
        const addressParsed = await addressAPI.validateOne(validatedAddress);
        return addressParsed;
    }

    handleValidate = async() => {
        
        const updatedAddress = {
          ...this.state.address,
        }
        updatedAddress.verified = !updatedAddress.verified;
        console.log(updatedAddress, "updated Address from handleValidate")

        //add regex later!
        const regex =  true;
    
        if(regex){
            this.setState({
                ...this.state,
                address: {...updatedAddress}
                })
            console.log(this.state.address, "address from Validate <-----------------")
            updatedAddress.user = this.state.address.user ? this.state.address.user._id : '';
            updatedAddress.house = this.state.address.house ? this.state.address.house._id : '';
            updatedAddress.roof = this.state.address.roof ? this.state.address.roof._id : '';
            updatedAddress.attic = this.state.address.attic ? this.state.address.attic._id : '';
            updatedAddress.waHeater = this.state.address.waHeater ? this.state.address.waHeater._id : '';
            updatedAddress.spHeater = this.state.address.spHeater ? this.state.address.spHeater._id : '';
            updatedAddress.utility = this.state.address.utility ? this.state.address.utility._id : '';
            
            const editedAddress = await this.validate(updatedAddress)
            console.log(editedAddress, "Post edit call <--------")
        
        }else{
    
          console.log("failed to verify")
        }
      }

      handleToggleChange = () => {
        this.handleValidate()
        }
    
      handleSubNavClick = (clickedPage) => {
        this.setState({
            ...this.state,
            page: clickedPage
        })
      }

  render(){
    const types = ['house', 'attic', 'roof', 'waHeater', 'spHeater', 'utility'];

    

    return(
      <>
        <Nav userId={this.props.user ? this.props.user._id : ''} handleLogout={this.props.handleLogout} />
        <Container>
          <Row>
            <Col md={11}>
                <Container className="flex-end">
                    <Link to="/admin/home">
                        <img style={{width: '14px'}} src="../SVG/Close.svg"/>
                    </Link>
                </Container>
            </Col>
            
          </Row>
          <Row >
            <Col md={{span: 12, offset: 1}}>
                <AddressLabelCard address={this.state.address} />

            </Col>
          </Row>
          <Row>
            <Col>
                <Container >
                    <SubNav page="show" activeLink={this.state.page} handleClick={this.handleSubNavClick}/>
                </Container>
            </Col>
            
          </Row>
          
                  {
                    this.state.page === "Data" ?
                    <Row>
                    <Col md={{span: 10, offset: 1}}>
                    <Container className="flex-end">
                    <div className='verified'>
                      <p className={this.state.address.verified ? 'verified' : 'not-verified'}>Verified</p>
                      
                      <Toggle isChecked={this.state.address.verified} handleChange={this.handleToggleChange}/>
                    </div>
                    </Container>
                    </Col>
            
                    </Row>
                    :
                    ''
                  }
               
          
          {
              this.renderPages()
          }
          
         
        </Container>
        
      </>
    )
  }
}


export default AdiminHome
