// toggle from jmh86 on codepen --> https://codepen.io/jmh86/pen/PoNmdOO
import React, { Component } from "react";
import './Toggle.css';

class Toggle extends Component {
    constructor(props) {
      super(props);
    //   this.state = {
    //     isChecked: props.isChecked,
    //   };
      
      this.handleChange = this.handleChange.bind(this);
    }
    handleChange() {
        this.props.handleChange()
    //   this.props.handleValidate()
    //   this.setState({ isChecked: !this.state.isChecked })
    }
    render () {
      return (
        <label className="switch">
          {this.props.isChecked ?
            <input type="checkbox"  onChange={this.handleChange} checked />
          :
            <input type="checkbox"  onChange={this.handleChange}  />
          }
          
          <div className="slider"></div>
        </label>
      );
    }
  }
  
  export default Toggle;