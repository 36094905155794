import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import '../../MyCasaDashboard/MyCasaDashboard.scss';
import { Button, Card, Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap';


const ShowAttic = (props) => {
  let attic = props.attic || 
    {
      atticImg: [],
      atticType : '',
      atticSqft: '',
      atticDepth: '',
      insulMaterial: '',
      airSealed: '',
      userId: '',
    }; 

  const photo = attic.atticImg;
  const userId = props.user._id;

  const undisplayedProps = ["_id", "userId", "__v", "atticImg"]  
  const displayPropsArr = Object.keys(attic).filter((allProps) =>
    !undisplayedProps.includes(allProps));
  // console.log(displayPropsArr, "<-----displaypropsarr");

  // uses map function to format and populate quick show with display props
  let items = displayPropsArr.map((item, index) => (
    <div key={index}>
      {attic[item]
      ? 
      <>
        <span className="checkNext"><img src="./../checkMark.svg" /></span>
        <span>{item[0].toUpperCase() + item.split(/(?=[A-Z])/).join(' ').slice(1).replace("nsul", "nsulation")}: {attic[item]}</span>
      </>
      :
      <>
        <span className="checkNext gray"><img src="./../checkMark.svg" /></span>
        <span className="gray">{item[0].toUpperCase() + item.split(/(?=[A-Z])/).join(' ').slice(1).replace("nsul", "nsulation")}</span>
      </>
      }
    </div>    
  ))

  // sets custom bootstrap toggle with expansion awareness
  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <div className="detailArr"><img className="down" src={!isCurrentEventKey ? "./../../dropdown_drop.svg" : "./../../dropdown_up.svg"} /></div>
    );
  }


  return(
    <div className="postedContainer">
      <div className="postedFrame">
      {props.attic ? 
        <Link to={"/mycasa/attic/edit"}><img className="postedImg" src={photo}/></Link>
        :
        <Link to="/mycasa/attic/create" className="blankContainer">
          <div className="blankFrame"><img className="placer" src="./../../upload.svg" /></div>
        </Link>  
      }  
      </div>
      <Accordion className="accordion">
        <Card className="card">
          <Card.Header className="header">
            <Accordion.Toggle variant="link" eventKey="0" className="toggleShow">
              <div className="postedLabel">Attic Details</div>
              <ContextAwareToggle eventKey="0"></ContextAwareToggle>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="cardBody">
              <div className="postedInfo">
                {/* <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.attic.atticType}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.attic.atticSqft}</span> Square Feet</div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.attic.atticDepth}</span> Inches</div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.attic.insulMaterial}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span>Air Sealed: <span>{props.attic.airSealed === "Yes" ? "Yes" : "No"}</span></div> */}
                {items}
              </div>
            </Card.Body>
           </Accordion.Collapse>
          </Card>
        </Accordion>
    </div>
  )
}
export default ShowAttic
