import React from "react";
import { Link, withRouter } from "react-router-dom";
// import { Navbar } from 'react-bootstrap/Navbar'
import Navbar from 'react-bootstrap/Navbar'
import "./Nav.scss";

const Nav = (props) => {
  // const userId = sessionStorage.getItem("userId");
  const userId = props.userId

  function logout(){
    props.logout();
  }
  // const logout = async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API}/api/v1/auth/logout`,
  //       {
  //         credentials: "include",
  //       }
  //     );

  //     if (!response.ok) {
  //       throw Error(response.statusText);
  //     }

  //     const responseParsed = await response.json();

  //     if (responseParsed.status === 200) {
  //       // const cookies = new Cookies();
  //       // cookies.remove('userId');
  //       sessionStorage.removeItem("userId");
  //       props.history.push("/");
  //     }
  //   } catch (err) {
  //     console.log("fail to logout");
  //   }
  // };

  return (
    <>
      <div className="navContainer">
        <Navbar  collapseOnSelect expand="lg" >
          <Navbar.Brand href="/">
            <div className="navRow" id="logo">
              {/* <Link to="/" style={{ textDecoration: "none" }}> */}
                <div className="logContainer">
                  <div className="logoImg" style={{ marginRight: "20px" }}>
                    <img src={process.env.PUBLIC_URL + "/logo.png"} />
                  </div>
                  <div className="logoText">
                    <img src={process.env.PUBLIC_URL + "/HouseGrades.png"} />
                  </div>
                </div>
              {/* </Link> */}
            </div>
          </Navbar.Brand>

          {!userId ? (

             <div className="navRow navLog ">
              <div className="navItem">
                <Link
                  to={`/login`}
                  className="links "
                  style={{ textDecoration: "none" }}
                  >
                  <div
                    className="navItemText"
                    id="loginSolo"
                    style={{ color: "#979797", padding: "8px 25px 10px 25px", border: "1px solid #139929", borderRadius: "5px"}}
                    >
                    Log In
                  </div>
                </Link>
              </div>
            </div>

          ) : (

            <>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" id="nav-toggle"/>
              <Navbar.Collapse style={{marginLeft: '31vw'}} id="responsive-navbar-nav">
                {/* <div className="navRow navSecond"> */}
                <div className="navItem">
                  <Link
                    to={`/mycasa/${userId}`}
                    className="links"
                    style={{ textDecoration: "none" }}
                  >
                    {window.location.pathname.split("/")[1] === "mycasa" ? (
                      <div
                        className="navItemText"
                        id="mycasa"
                        style={{ color: "black" }}
                      >
                        My House
                      </div>
                    ) : (
                      <div
                        className="navItemText"
                        id="mycasa"
                        style={{ color: "#979797" }}
                      >
                        My House
                      </div>
                    )}
                  </Link>
                </div>
                <div className="navItem">
                  <Link
                    to={`/home`}
                    className="links"
                    style={{ textDecoration: "none" }}
                    >
                    {window.location.pathname.split("/")[1] === "home" ? (
                      <div
                      className="navItemText"
                      id="electrify"
                      style={{ color: "black" }}
                      >
                        Grades
                      </div>
                    ) : (
                      <div
                        className="navItemText"
                        id="electrify"
                        style={{ color: "#979797" }}
                      >
                        Grades
                      </div>
                    )}
                  </Link>
                </div>
                
                <div className="navItem">
                  <Link
                    to={`/myproject/${userId}`}
                    className="links"
                    style={{ textDecoration: "none" }}
                  >
                    {window.location.pathname.split("/")[1] === "myproject" ? (
                      <div
                        className="navItemText"
                        id="myproject"
                        style={{ color: "black" }}
                      >
                        Projects
                      </div>
                    ) : (
                      <div
                        className="navItemText"
                        id="myproject"
                        style={{ color: "#979797" }}
                      >
                        Projects
                      </div>
                    )}
                  </Link>
                </div>
                <div className="navItem">
                  <Link
                    to={`/myaccount/${userId}`}
                    className="links"
                    style={{ textDecoration: "none" }}
                  >
                    {window.location.pathname.split("/")[1] === "myaccount" ? (
                      <div
                        className="navItemText"
                        id="myaccount"
                        style={{ color: "black" }}
                      >
                        Account
                      </div>
                    ) : (
                      <div
                        className="navItemText"
                        id="myaccount"
                        style={{ color: "#979797" }}
                      >
                        Account
                      </div>
                    )}
                  </Link>
                </div>
                <div className="navItem">
                    <button onClick={() => props.handleLogout()} id="logout">
                      <div className="navItemText">Log Out</div>
                    </button>
                  </div>
                {/* </div>   */}
              </Navbar.Collapse>
            </>
          )}
        </Navbar>
      </div>
    </>
  );
};

export default withRouter(Nav);
