import React from "react";
import ElectrifyLayout from './ElectrifyLayout';
import './ElectrifyComponents.scss';

const EnergyGeneration = (props) => {
  return (

    <ElectrifyLayout
          userId={props.userId} handleLogout={props.handleLogout}
          title="Energy Generation"
          electrifyIcon="../Electrify/EnergyGeneration.svg"
          includedInRating="Impacts Electrification Grade"
          p1="Rooftop solar is often the first thing people consider when trying
              to find ways to reduce their carbon footprint, and it makes sense."
          p2="At HouseGrades, we like to think big, and that’s why we recommend
              that solar actually be the last decision a homeowner makes. The
              reason for this is that solar is sized based on current energy
              consumption. If over half of your energy consumption is fossil
              fuels, the size of the solar array designed and installed will
              actually only cover part of your carbon footprint. Better to first
              electrify your house, and then consider solar. That way, the solar
              power system can be sized to meet 100% of your energy needs and
              not just a fraction. By then, you may have an electric car to
              charge as well."
            p3="The price for rooftop solar is approximately $3.00 per kW
            before rebates and incentives."
    />

  );
};

export default EnergyGeneration;
