import React from "react";
import ElectrifyLayout from './ElectrifyLayout';
import './ElectrifyComponents.scss';

const HeatingCooling = (props) => {
  return (

    <ElectrifyLayout
          userId={props.userId} handleLogout={props.handleLogout}
          title="Heating and Cooling"
          electrifyIcon="../Electrify/HeatingCooling.svg"
          includedInRating="Impacts Electrification Grade"
          p1="Approximately 31-47% of residential home energy usage is for
              heating and cooling. Heating can be provided either by gas or
              electricity."
          p2="Most room and central heaters use gas, because it has been, until
              recently, the least expensive fuel, however, new heat pumps are
              3-8x more efficient than gas. They also offer better ventilation."
          p3="The advantage of heat pumps is that they can be powered by
              electricity generated from renewable sources such as rooftop
              solar. Switching to a heat pump is essential to lower your carbon
              footprint."
          p4="The price range is: $7-$9k for mini-splits and $12-15k
              for ducted heat pumps, but it varies on the size."
    />

  );
};

export default HeatingCooling;
