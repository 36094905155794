import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import '../../MyCasaDashboard/MyCasaDashboard.scss';
import { Card, Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap';


const ShowWaHeater = (props) => {
  let waHeater = props.waHeater || 
    {
      waHeaterImg: [],
      waHeatertype : '',
      waHeaterBrand: '',
      waHeaterYear: '',
      waHeaterCondition: '',
      waHeaterSingle: '',
      userId: '',
    } 

  const photo = waHeater.waHeaterImg;
  // const userId = sessionStorage.getItem('userId');
  const userId = props.user._id;
  console.log(waHeater.waHeaterSingle)


  const undisplayedProps = ["_id", "userId", "__v", "waHeaterImg"]  
  const displayPropsArr = Object.keys(waHeater).filter((allProps) =>
    !undisplayedProps.includes(allProps));
  // console.log(displayPropsArr, "<-----displaypropsarr");

  // uses map function to format and populate quick show with display props
  let items = displayPropsArr.map((item, index) => (
    <div key={index}>
      {waHeater[item]
      ? 
      <>
        <span className="checkNext"><img src="./../checkMark.svg" /></span>
        <span>{(item[0].toUpperCase() + item.split(/(?=[A-Z])/).join(' ').slice(1)).replace("Wa", "Water").replace("Heatertype", "Heater Type")}: {waHeater[item]}</span>
      </>
      :
      <>
        <span className="checkNext gray"><img src="./../checkMark.svg" /></span>
        <span className="gray">{(item[0].toUpperCase() + item.split(/(?=[A-Z])/).join(' ').slice(1)).replace("Wa", "Water").replace("Heatertype", "Heater Type")}</span>
      </>
      }
    </div>    
  ))  

  // sets custom bootstrap toggle with expansion awareness
  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    // const currentEventKey = useAccordionEventKey();;
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <div className="detailArr"><img className="down" src={!isCurrentEventKey ? "./../../dropdown_drop.svg" : "./../../dropdown_up.svg"} /></div>
    );
  }

  return(
    <div className="postedContainer">
      <div className="postedFrame">
      {props.waHeater ? 
        <Link to={"/mycasa/waheater/edit"}><img className="postedImg" src={photo}/></Link>
        :
        <Link to="/mycasa/waheater/create" className="blankContainer">
          <div className="blankFrame"><img className="placer" src="./../../upload.svg" /></div>
        </Link>  
      }        
      </div>
        <Accordion className="accordion">
          <Card className="card">
            <Card.Header className="header">
              <Accordion.Toggle variant="link" eventKey="0" className="toggleShow">
                <div className="postedLabel">Water Heater Details</div>
                <ContextAwareToggle eventKey="0"></ContextAwareToggle>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="cardBody">
                <div className="postedInfo">
                  {/* <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.waHeater.waHeatertype}</span></div>
                  <div><span className="checkNext"><img src="./../checkMark.svg" /></span>Brand : <span>{props.waHeater.waHeaterBrand}</span></div>
                  <div><span className="checkNext"><img src="./../checkMark.svg" /></span>Year Built: <span>{props.waHeater.waHeaterYear}</span><span className="spanNext"></span></div>
                  <div><span className="checkNext"><img src="./../checkMark.svg" /></span>Condition : <span>{props.waHeater.waHeaterCondition === "Yes" ? "Good" :  "Not Good"}</span></div>
                  <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.waHeater.waHeaterSingle === "Yes" ? "Multiple Water Heaters" : "Single Water Heater"}</span></div> */}
                  {items}
                </div>
              </Card.Body>
             </Accordion.Collapse>
            </Card>
          </Accordion>
    </div>
  )
}
export default ShowWaHeater
