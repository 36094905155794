import React, { useState } from "react";
import Footer from "../Footer/index";
import Nav from "../Nav/index";
import LandingPageTop from "./SubTop/LandingPageTop";
import LandingPageMid from "./SubMid/LandingPageMid";
import LandingPageBot from "./SubBot/LandingPageBot";
import LandingPageLast from "./SubLast/LandingPageLast";

import MobileLandingBot from "./MobileLanding/MobileLandingBot";

import "./MobileLanding/MobileLanding.scss";

export default class LandingPage extends React.Component {
  constructor() {
    super();
    this.state = {
      mobileView: window.matchMedia("(max-width: 700px)").matches,
    };
  }

  render() {
    return (
      <>
        <Nav userId={this.props.userId} handleLogout={this.props.handleLogout} />
        <LandingPageTop userId={this.props.userId}/>
        <LandingPageMid />
        {/* <LandingPageBot /> */}
        {this.state.mobileView ? <MobileLandingBot /> : <LandingPageBot />}
        <LandingPageLast userId={this.props.userId}/>
        <Footer />
        
      </>
    );
  }
}
