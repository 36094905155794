import React from 'react';
import './ProjectsCard.css';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import ProjectsCardDetails from '../ProjectsCardDetails';

export default function ProjectsCard({image, componentTitle, projectTitle, projectYear, blurbDescription, estimate}){
    return(
        
        <Col md={4}>
        <Container className="project-card">
            <Row>
                <Col >
                    <img className="img-project-card" src={image} alt="Project Image"/>
                </Col>
                
            </Row>
            <Row>
                <Col >
                    <ProjectsCardDetails
                    componentTitle={componentTitle}
                    projectYear={projectYear}
                    projectTitle={projectTitle}
                    blurbDescription={blurbDescription}
                    estimate={estimate}
                    />
                </Col>
                
            </Row>
            </Container>
        </Col>
        
    )
}