import React from "react";
import Nav from "./../Nav";
import './ElectrifyComponents.scss'
import ElectrifyLayout from './ElectrifyLayout';

const Cooking = (props) => {
  return (

    <ElectrifyLayout
          userId={props.userId} handleLogout={props.handleLogout}
          title="Cooking"
          electrifyIcon="../Electrify/Cooking.svg"
          includedInRating='Not Rated ("NR") in Electrification Grade'
          p1="Some people love cooking with gas and don’t believe it’s possible
              there could be a better way. That is a fair opinion, although the
              latest academic research shows cooking with gas produces nitrous
              oxide and sometimes also carbon monoxide at levels that can
              cause respiratory problems, especially for kids. From a
              climate perspective, cooking is not responsible for a substantial
              amount of emissions. Approximately 6% of residential home energy
              usage is for cooking. At this time, we have excluded it from our
              ratings."
          p2="A true all-electric home with zero emissions would exclude gas for
              cooking. Luckily, there are excellent alternatives. Induction
              cooktops are more efficient than gas. They are faster and safer.
              They also require less space and ventilation.
              If replacing your cooking appliance, we recommend looking into
              electric induction. If you'd like to experiement with cooking
              with induction, a plug-in cooktop is low cost and convenient."
            p3="The price for a plug-in induction cooktop is as low as $50,
            and the price for a slide-in induction range starts at approximately
            $1400."
    />

  );
};

export default Cooking;
