import React, { Component } from "react";
import PhotoGrade from '../../Shared/PhotoGrade/PhotoGrade';
import './PhotoContainer.css';

class PhotoContainer extends Component {
    constructor(props) {
      super(props);
  
    //     //the Grade with the lowest rating has the highest ASCII value
    //     // and the color should match the lowest grade
   
    }
    render () {
      return (
          
            
            this.props.verified ? 
                <>
                <img className="house-img-tag" src={this.props.photo} />
                 <div className="house-image" style={{backgroundImage: `url(${this.props.photo})`}}>
                 <PhotoGrade 
                    BuildingEfficiencyGrade={this.props.BuildingEfficiencyGrade} 
                    ElectrificationGrade={this.props.ElectrificationGrade}
                    GradeColor={(this.props.BuildingEfficiencyGrade > this.props.ElectrificationGrade) ? this.props.BuildingEfficiencyColor : this.props.ElectrificationColor}
                    />
                </div>
                </>
            :
                 <img className="house-img-not-verified" src={this.props.photo} />
            
        
            
       
        
        
        
        
      );
    }
  }
  
  export default PhotoContainer;