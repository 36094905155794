import React from "react";
import "./LandingPageTop.scss";
import { ElLink } from "../../Shared/Button/Button.jsx";

export default class LandingPageTop extends React.Component {
  constructor(props) {
    super(props);
    this.mobileView = window.matchMedia("(max-width: 700px)").matches;

  }


  render() {
    return (
      <div className="container pt-4 pb-4 ">
      <div className="sub-top-container">
        <div className=" top-heading mt-4 mb-4 justify-content-start">
          <h1>Your Home Energy Advisor</h1>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col">
            <p style={{paddingRight: '10px'}}>
              HouseGrades provides energy ratings for single-family homes. The grades from A to D help owners and buyers quickly understand energy assets and make actionable plans for electrification. Cities who adopt HouseGrades for real estate listings will accelerate building decarbonization and sustainability. 
            </p>
            {/* <div className="row mt-5 justify-content-center align-items-center"> */}
            <div className="mt-5 justify-content-center align-items-center ">
              {/* <div className="justify-content-center"> */}
              {!this.props.userId ?
                <ElLink link="/signup" text="sign up"  extraClasses="button-narrow mx-auto"/>
              :
              ''
              }
              {/* </div> */}
            </div>
          </div>
          <div className="col no-phone ">
            <img
              src={"./LandingPage/LandingTopPic.png"}
              alt="Picture of a House"
            />
          </div>
        </div>
      </div>
      </div>
    );
  }
}
