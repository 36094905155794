import React from 'react';
import AdminTableRow from '../AdminTableRow';

import Table from 'react-bootstrap/Table';

import './AdminTable.css';

export default function AdminTable(props){

    return(
    <Table className="admin-table" size="lg">
        <thead>
        <tr className="admin-table-header">
            <th>Name</th>
            <th>Street</th>
            <th>City</th>
            <th>State</th>
            <th>Zip Code</th>
            <th>AID</th>
            <th>WH</th>
            <th>SH</th>
            <th>Status</th>
        </tr>
        </thead>
        <tbody>
            {props.addresses.map(address =>{
                return <AdminTableRow key={address._id} address={address} user={props.user}/>
            })}
        </tbody>
       
    </Table>
    )

}