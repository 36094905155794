import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Nav from '../../../Nav';
import AdminTable from '../../AdminComponents/AdminTable';
import SubNav from '../../AdminComponents/SubNav';

import * as addressAPI from '../../../utils/addressService';

//Bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// --------------------------- TO DO ---------------------------------------
// State setup for rendering Filters --> 
//          All, Verified, Completed, Attention, Drafts

// State setup for pagination
            // 1- 50 = page 1, 51 - 100 = page 2, etc 

// Search bootstrap / component libraries for searchbar and scroll bar

class AdiminHome extends Component {
  constructor(props){
    super(props)
    this.state = {
      addresses: [],
      filtered: [],
      filter: 'All'
    }
  }

  componentDidMount(){
    this.getAllAddresses();
    
  };

    getAllAddresses = async() => {
      try{
        const addressesParsed = await addressAPI.getAllAddresses();

        this.setState({
          ...this.state,
          addresses: addressesParsed.allAddresses,
          filtered: addressesParsed.allAddresses,
          filter: 'All'
        })
        console.log(this.state.addresses[0].house, "addresses <-------");
        // console.log(this.props, "props <-------");
        }catch(err){
          this.props.history.push('/signup')
          return err
      }
    }

    handleSubNavClick = (filter) => {
      switch(filter){
        case 'All':
          this.setState({
            ...this.state,
            filtered: [...this.state.addresses],
            filter: 'All'
          })
          break;
        case 'Verified':
          const verified = [];
          this.state.addresses.forEach(address => {
            if(address.verified){
              verified.push(address);
            }
          })
          console.log(verified, "verified from subnav <------")
          this.setState({
            ...this.state,
            filtered: [...verified],
            filter: 'Verified'
          })
          break;
        case 'Attention':
          this.setState({
            ...this.state,
            filtered: [...this.state.addresses],
            filter: 'Attention'
          })
          break;
        case 'Completed':
          const completed = [];
          
          this.state.addresses.forEach(address => {
            if(address.completed){
              completed.push(address);
            }
          })
          this.setState({
            ...this.state,
            filtered: [...completed],
            filter: 'Completed'
          })
          break;
        case 'Drafts':
          const notCompleted = [];
          
          this.state.addresses.forEach(address => {
            if(!address.completed){
              notCompleted.push(address);
            }
          })
          this.setState({
            ...this.state,
            filtered: [...notCompleted],
            filter: 'Drafts'
          })
          break;
      }
    }


  render(){

    return(
      <>
        <Nav userId={this.props.user ? this.props.user._id : ''} handleLogout={this.props.handleLogout} />
        
        <Container>
        
          <Row style={{marginTop: "1rem"}}><h3>Houses</h3></Row>

          <Row>
            <Col>
                <Container >
                  <SubNav page="index" activeLink={this.state.filter} handleClick={this.handleSubNavClick}/>
                </Container>
            </Col>
            
          </Row>


          {/* <Row style={{marginBottom: '2rem', marginTop: '1rem'}}>

            <Col xs={{span: 8}} sm={{span: 8}} md={{span: 8}} lg={{span: 8}}>
                
                    <SubNav page="index" activeLink={this.state.filter} handleClick={this.handleSubNavClick}/>
                
            
            </Col>
            
          </Row> */}
          
          <Row md={{span: 8, offset: 2}} style={{marginTop: '4vw'}}>
          {
            this.state.filtered ?
            <AdminTable user={this.props.user} addresses={this.state.filtered} />
            :
            ''
          }
            
          </Row>
          
        </Container>
        
      </>
    )
  }
}


export default AdiminHome
