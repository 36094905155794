import React, { Component } from "react";
import Nav from "../Nav";
import { Link } from "react-router-dom";
import "./Home.scss";
import content from "./content.json";

class HomeContainer extends Component {
  constructor() {
    super();
    this.state = {
      userinfo: {
        email: "",
        password: "",
        name: "",
      },
    };
  }


  render() {
    return (
      <>
      <Nav userId={this.props.userId} handleLogout={this.props.handleLogout}/>
      <div className="container homeContainer">
        <div className="my-5 homeContainer__titleContainer">
          <h2 className="homeContainer__titleContainer-title">Grades</h2>
          <p className="homeContainer__titleContainer-subtitle">
            Understand the factors that impact BEE grades. Explore home energy improvements to increase comfort, efficiency, safety and health, and lower carbon footprint. 
          </p>
        </div>
        <div className="homeContainer__contentContainer">
          <div className="row homeContainer__contentContainer--list">
            {content.map(( data, i ) => {
              return (
                <Link to={data.to} key={i} className="links col-lg-3 col-md-4 col-sm-6 col-6 my-1">
                  <div className="py-5 homeContainer__contentContainer--list--item">
                    <img src={data.img} className="homeContainer__contentContainer--list--item-img"/>
                    <div className="homeContainer__contentContainer--list--item-title">{data.title}</div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default HomeContainer;


