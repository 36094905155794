import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import DataDetailCard from '../DataDetailCard';
import EditModal from '../EditModal';

import './DataCard.css';


export default function DataCard({type, data, history}){
    console.log("data card <----")

    function getHeader(type){
        switch(type){
            case 'house':
                return 'House';
            case 'attic':
                return 'Attic Insulation Details';
            case 'roof':
                return 'Roof Detail';
            case 'waHeater':
                return 'Water Heater Details';
            case 'spHeater':
                return 'Heating and Cooling';
            case 'utility':
                return 'Utility Bills'
        }
    }
    
    return(
        <>
                {
        data ?
        
        <Container className="admin-card">
            <Row>
            <Col >
            <Row>
                <Col md={12}>
                    <Container fluid className="admin-card-header">
                        <h4 style={{justifySelf: "flexStart"}}>{getHeader(type)}</h4>
                        {/* <h4 style={{justifySelf: "flexEnd"}}>Edit</h4> */}
                        <EditModal style={{justifySelf: "flexEnd"}} type={type} data={data} history={history}/>
                        
                    </Container>
                    <hr></hr>

                    
                </Col>
            </Row>
            <Row>
                <Col >
                    <Container fluid className="admin-col">
                        <img  className="img" src={`${data[type + 'Img']}` } />
                    </Container>

                </Col>
                <Col className="admin-col">
                    <DataDetailCard type={type} data={data} />
                </Col>
            </Row>
            </Col>
            </Row>
        </Container>
        
        :
        <div>Not Completed</div>
        } 
        </>
        
    )
        
    
}