import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import ProjectsCard from '../ProjectsComponents/ProjectsCard';
import MissingDataCard from '../ProjectsComponents/ProjectsCard/MissingDataCard';

export default function ProjectsPage({address}){

    // -------------------------- Attic logic functions ----------------------------------------------

    function gradeAttic(attic){

        const depth = Number(attic.atticDepth);
        console.log(depth, "<------- depth from atticGrade")
        if(depth <= 1){
            return "D"
          }else if(depth > 1 && depth < 6){
            return "C"
          }else if(depth >= 6 && depth < 11){
            return "B"
          }else if(depth >= 11){
            return "A"
          }
    }

    function returnAtticProjectYear(data){
        console.log(data, "<-----attic data project year")
        switch(gradeAttic(data.attic)){
            case "D":
                return new Date().getFullYear()
            case "C":
                return new Date().getFullYear()
            case "B":
                return returnSpHeaterProjectYear(data.spHeater.spHeaterYear)
            case "A":
                return new Date().getFullYear() + 25
            default:
                return new Date().getFullYear()
        }
    }

    function returnAtticEstimate(sqft){
        const a = Number(sqft) * 1.5;
        const b = Number(sqft) * 2.5;
        return `$${a.toFixed()} - $${b.toFixed()}`;
    }

    // -------------------------- Heater logic function ----------------------------------------------



    function returnSpHeaterProjectYear(heaterYear){
        
        const year = new Date().getFullYear();
        const age = year - Number(heaterYear);
        console.log(age, year, "age and year<-----")
        if(age >= 15){
            return year
        } else {
           
                return Number(heaterYear) + 15
            
            
        }
    }

    function returnWaHeaterProjectYear(heaterYear){
        
        const year = new Date().getFullYear();
        const age = year - Number(heaterYear);
        console.log(age, year, "age and year<-----")
        if(age >= 10){
            return year
        } else {
           
                return Number(heaterYear) + 10
            
            
        }
    }
    
     // -------------------------- Button Color logic function ----------------------------------------------

    return(
        <Container >
            <Row>


                {address.attic && address.attic.atticDepth ?
                    <ProjectsCard
                        image='..//Projects/AtticProject.jpg'
                        componentTitle='Attic'
                        projectYear={returnAtticProjectYear(address)}
                        projectTitle='Insulation and Air Sealing'
                        blurbDescription='Building Efficiency Improvement'
                        estimate={address.attic.atticSqft ? returnAtticEstimate(address.attic.atticSqft) : "$1.50 - $2.50 / square foot"}
                        grade={gradeAttic(address.attic.atticDepth)}
                    />
                :
                    <MissingDataCard 
                        image='..//Projects/AtticProject.jpg'
                        dataMissing="Your analysis is missing Attic Data"
                    /> 
                }

                {address.waHeater && address.waHeater.waHeaterYear ?
                    <ProjectsCard
                        image='..//Projects/WaterHeaterProject.jpg'
                        componentTitle='Water Heater'
                        projectYear={returnWaHeaterProjectYear(address.waHeater.waHeaterYear)}
                        projectTitle='Heat Pump'
                        blurbDescription='Electrification Improvement'
                        estimate='$4-6k'
                    />

                :
                <MissingDataCard 
                        image='..//Projects/WaterHeaterProject.jpg'
                        dataMissing="Your analysis is missing Water Heater Data"
                    /> 
                }

                {address.spHeater && address.spHeater.spHeaterYear ?
                    <ProjectsCard
                        image='..//Projects/SpaceHeaterProject.jpg'
                        componentTitle='Space Heater'
                        projectYear={returnSpHeaterProjectYear(address.spHeater.spHeaterYear)}
                        projectTitle='Heat Pump'
                        blurbDescription='Electrification Improvement'
                        estimate='$7-9k (mini-split) $12-15k (ducted)'
                    />
                :
                <MissingDataCard 
                        image='..//Projects/SpaceHeaterProject.jpg'
                        dataMissing="Your analysis is missing Space Heater Data"
                    /> 
                }


            </Row>
        </Container>
    )
}
