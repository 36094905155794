import React from "react";
import './About.scss';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Nav from "../Nav";
import Footer from "../Footer/index";

const About = (props) => {
  return (
    <>
      <Nav userId={props.userId} handleLogout={props.handleLogout}/>
      <Container style={{marginTop: '4rem'}} >
        <Row>
          <div className="about_title mx-md-5">
            <h3 className="h3_about">About HouseGrades</h3>
          </div>
        </Row>

        <Row className="justify-content-center">
          <div className="item_container">
              <div className="logo_box">
                <img className="logo_about" src="../bigLogo.png" />
              </div>
          </div>
        </Row>

        <Row className="justify-content-center">
          <Col md={12} >
            <div className="item_container mx-md-5">
                  <p className="p_about">HouseGrades was created in 2019 in Oakland, California. It won an award at the Suncode Hackathon.</p>
                  <p className="p_about">Next, it was provided with a Yale Renewable Thermal Alliance 2019 Seed Innovation Grant, that funded its early development.</p>
                  <p className="p_about">It is currently in Alpha testing. We welcome and encourage feedback.</p>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="footer_about">
        <Footer />
      </div>
    </>
  )
};

export default About;
