import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import '../../MyCasaDashboard/MyCasaDashboard.scss';
import { Card, Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap';


const ShowUtility = (props) => {
  let utility = props.utility || 
    {
      utilityImg: [],
      utilityName : '',
      electricityUsageKwh: '',
      electricityUsageDollar: '',
      gasUsageTherms: '',
      gasUsageDollar: '',
      highBilling:'',
      oldEquipment:'',
      userId: '',
    }; 

  const photo = utility.utilityImg;
  const userId = props.user._id;

  const undisplayedProps = ["_id", "userId", "__v", "utilityImg"]  
  const displayPropsArr = Object.keys(utility).filter((allProps) =>
    !undisplayedProps.includes(allProps));
  // console.log(displayPropsArr, "<-----displaypropsarr");

  // uses map function to format and populate quick show with display props
  let items = displayPropsArr.map((item, index) => (
    <div key={index}>
      {utility[item]
      ? 
      <>
        <span className="checkNext"><img src="./../checkMark.svg" /></span>
        <span>{item[0].toUpperCase() + item.split(/(?=[A-Z])/).join(' ').slice(1)}: {utility[item]}</span>
      </>
      :
      <>
        <span className="checkNext gray"><img src="./../checkMark.svg" /></span>
        <span className="gray">{item[0].toUpperCase() + item.split(/(?=[A-Z])/).join(' ').slice(1)}</span>
      </>
      }
    </div>    
  ))

  // sets custom bootstrap toggle with expansion awareness
  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <div className="detailArr"><img className="down" src={!isCurrentEventKey ? "./../../dropdown_drop.svg" : "./../../dropdown_up.svg"} /></div>
    );
  }

  return(
    
    <div className="postedContainer">
      <div className="postedFrame">
      {props.utility ? 
        <Link to={"/mycasa/utility/edit"}><img className="postedImg" src={photo}/></Link>
        :
        <Link to="/mycasa/utility/create" className="blankContainer">
          <div className="blankFrame"><img className="placer" src="./../../upload.svg" /></div>
        </Link>  
      }  
      </div>
      <Accordion className="accordion">
        <Card className="card">
          <Card.Header className="header">
            <Accordion.Toggle variant="link" eventKey="0" className="toggleShow">
              <div className="postedLabel">Utility Details (Optional)</div>
              <ContextAwareToggle eventKey="0"></ContextAwareToggle>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="cardBody">
              {/* <div className="postedInfo">
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.utility.utilityName}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.utility.electricityUsageKwh}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.utility.electricityUsageDollar}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.utility.gasUsageTherms}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.utility.gasUsageDollar}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.utility.highBilling}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.utility.oldEquipment}</span></div>
              </div> */}

              <div className="postedInfo">
                {items}
              </div>
            </Card.Body>
           </Accordion.Collapse>
          </Card>
        </Accordion>
    </div>
  )
}
export default ShowUtility
