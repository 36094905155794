import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import './ProjectCardDetails.css'

export default function ProjectsCardDetails({componentTitle, projectYear, projectTitle, blurbDescription, estimate}){

    function returnYearButtonColor(projectYear){
        const year = projectYear - (new Date().getFullYear())
        console.log(year, "year from color function <-------")
        if(year >=4){
            return 'green'
        }else if(year < 4 && year >= 2){
            return 'orange'
        } else {
            return 'red'
        }
    }

    return(
        <Col>
            <Row>
                <Col>
                    <div className='project-header'>
                        <div className="component-title">
                        <h3 className="component-title">{componentTitle}</h3>
                        </div>
                    
                        <div className={returnYearButtonColor(projectYear) + " project-year"}>
                            <p className="project-year">
                                {projectYear}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 className="project-blurb">{projectTitle}</h4>
                </Col>
                
            </Row>
            <Row>
                <Col >
                    <p className="project-blurb">{blurbDescription}</p>
                </Col>
                
            </Row>
            <Row>
                <Col style={{display: 'flex', justifyContent: 'center'}}>
                <div className="estimate">
                <p className="estimate">Estimate</p>
                <p className="estimate"><strong>{estimate}</strong></p>
                </div>
                    
                </Col>
                
            </Row>
        </Col>
    )
}