import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './DataDetailCard.css';

export default function DataDetailCard({data, type, key}){

    
    // create objects with keys and values that correspond to the card content
    function getCardContent(type, data){
        
        switch(type){
            case 'house':
                return {
                    "Address": data.address ? data.address : "",
                    "City, State, Zip": `${data.city ? data.city : ""} ${data.state ? data.state : ""} ${data.zipcode ? data.zipcode : ""}`,
                    "Year Built": data.houseYear ? data.houseYear : "",
                    "Square Feet": data.houseSqft ? data.houseSqft : ""
                };
            case 'roof':
                return {
                    "Exterior Finish": data.exterior ? data.exterior : "",
                    "Color": data.roofColor ? data.roofColor : "",
                    "PV System": data.pvSystem ? data.pvSystem : "",
                    "Number of Panels": data.panels ? data.panels : "",
                    "Size (kW)": data.dcCapacity ? data.dcCapacity : ""
                };
            case 'attic':
                return{
                    "Primary Attic Type": data.atticType ? data.atticType : "",
                    "Attic Square Footage (guess)": data.atticSqft ? data.atticSqft : "",
                    "Estimated Average Depth (inch)": data.atticDepth ? data.atticDepth : "",
                    "Insulation Material": data.insulMaterial ? data.insulMaterial : "",
                    "Has the House Been Professionally Air Sealed?" : data.airSealed ? data.airSealed : ""
                }
                case 'waHeater':
                    return{
                        "Type of Water Heater": data.waHeatertype ? data.waHeatertype : "",
                        "Brand": data.waHeaterBrand ? data.waHeaterBrand : "",
                        "Year of Manufacture": data.waHeaterYear ? data.waHeaterYear : "",
                        "Is the System Working Well?": data.waHeaterCondition ? data.waHeaterCondition : "",
                        "Is There More Than One Water Heater?" : data.waHeaterSingle ? data.waHeaterSingle : ""
                    }
                case 'spHeater':
                    return{
                        "Type of Space Heater": data.spHeaterType ? data.spHeaterType : "",
                        "Brand": data.spHeaterBrand ? data.spHeaterBrand : "",
                        "Year of Manufacture": data.spHeaterYear ? data.spHeaterYear : "",
                        "Is the System Working Well?": data.spHeaterCondition ? data.spHeaterCondition : "",
                        "Is There a Cooling System?" : data.coolingSystem ? data.coolingSystem : ""
                    }
                    case 'utility':
                        return{
                            "Utility Name": data.utilityName ? data.utilityName : "",
                            "Electricity Usage, kwh": data.electricityUsageKwh ? data.electricityUsageKwh : "",
                            "Electricity Usage, $": data.electricityUsageDollar ? data.electricityUsageDollar : "",
                            "Gas Usage, therms": data.gasUsageTherms ? data.gasUsageTherms : "",
                            "Gas Usage, $" : data.gasUsageDollar ? data.gasUsageDollar : "",
                            "Are your energy bills too high?": data.highBilling ? data.highBilling : "",
                            "Do you have any equipment older than 25 years eg. an old freezer?": data.oldEquipment ? data.oldEquipment : ""
                        }
               
        }
       
    }

    function displayContent (content){
        //Object.entries
        const contentArray = [];
        for (const [idx, [heading, value]] of Object.entries(Object.entries(content))) {
            contentArray.push(
                <Container key={idx}>
                    <Row >
                        <Col><h5>{heading}</h5></Col>
                        <Col><p>{value}</p></Col>
                    </Row> 
                </Container>
            )
          }
        return contentArray;
    }

    return(
        <>
        {
        data ?
        <div>
            {displayContent(getCardContent(type, data))}
        </div>
        :
        <div>Not Completed</div>
        }   
        
            
            
        </>
        
    )
}