import React from 'react';
import GradeGraph from '../GradeGraph';
import Components from '../Components';
import AtticBar from '../AtticBar';
import WaHeaterBar from '../WaHeaterBar';
import SpHeaterBar from '../SpHeaterBar';
import PhotoContainer from '../PhotoContainer';
//
const Exam = (props) => {
  const userId = props.user._id;
  console.log('props - ', props.waRecommendation)

  return(
    <div className="examContainer">

      {/* <div className="titleContainer">
        <div className="title h2">Projects</div>
        <div className="subtitle h4">See the status of your house energy assets and plan upgrades at optimal times to maximize savings and prior to emergencies.</div>
      </div> */}

      <div className="gradeContainer">
        <div className="gradeRow top">
          <div className="myGrade">
            <div className="houseProfile">

                <div className="house-row">
                  <div className="h3">House</div>
                  <div className="verified">
                    {props.address.verified ? <img className="verified-img" src="/SVG/Verify.svg"/> : <img className="verified-img" src="/SVG/Verify-inactive.svg"/>}

                  </div>
                </div>
              <div className="houseProfileRow">
                <PhotoContainer
                  photo={props.house.houseImg}
                  BuildingEfficiencyGrade={props.atticGrade}
                  ElectrificationGrade={props.carbonFootScore}
                  BuildingEfficiencyColor={props.atticGradeColor}
                  ElectrificationColor={props.carbonFootColor}
                  verified={props.address.verified}
                  />
                {/* <img src={props.house.houseImg} /> */}
                <div className="houseText">
                  <div>{props.house.address}</div>
                  <div>{props.house.city}, {props.house.state}, {props.house.zipcode}</div>
                </div>
              </div>
            </div>
            <div className="measureContainer">
            <div className="grades-row">
              <div className="h3">Grades</div>
              <div className="verified">
              {props.address.verified ? <img src="/SVG/Verify.svg"/> : <img src="/SVG/Verify-inactive.svg"/>}

              </div>
            </div>

              <div className="measureRow">
                <div className="measureItem">
                  {/* <label><div className="h4">Building Efficiency</div><div className="h4">Rating</div></label> */}
                  <label><div className="h4 h4_grade_label">Building Efficiency</div></label>
                  <div className="atticGrade" style={{color:`${props.atticGradeColor}`}}>{props.atticGrade}</div>
                  <GradeGraph user={props.user} atticGrade={props.atticGrade} />
                </div>
                <div className="measureItem">
                  {/* <label><div className="h4">Electrification</div><div className="h4">Rating</div></label> */}
                  <label><div className="h4 h4_grade_label">Electrification</div></label>
                  <div className="spGradeLetter" style={{color:`${props.carbonFootColor}`}}>{props.carbonFootScore}</div>
                  <GradeGraph user={props.user} spGradeLetter={props.carbonFootScore} />
                </div>
              </div>
              <div className="gradeRow componentsMark">
                <Components user={props.user} atticInsulation={props.atticInsulation} attic={props.attic} waHeatertypeShort={props.waHeatertypeShort} spHeaterTypeShort={props.spHeaterTypeShort} roof={props.roof}/>
              </div>
            </div>
          </div>
        </div>

        <div className="gradeRow analysis">
          <div className="h3">Analysis</div>
            <div className="analysisSection">
              <div className="analysisName h4">EFFICIENCY</div>
              <AtticBar user={props.user} attic={props.attic}atticDepth={props.atticDepth} atticGrade={props.atticGrade} atticGradeColor={props.atticGradeColor} atticInsulation={props.atticInsulation} />
            </div>
            <div className="analysisSection">
              <div className="analysisName h4">TECHNOLOGY</div>
              <WaHeaterBar user={props.user} waHeatertype={props.waHeatertype} waHeaterAge={props.waHeaterAge} waEfficency={props.waEfficency} waGradeColor={props.waGradeColor} waGradeLetter={props.waGradeLetter}/>
            </div>
            {/* <div className="analysisSection"> */}
              <SpHeaterBar user={props.user} spHeaterType={props.spHeaterType} spHeaterAge={props.spHeaterAge} spEfficency={props.spGradeLetter} spGradeColor={props.spGradeColor} spGradeLetter={props.spGradeLetter}/>
            {/* </div> */}
          </div>
        </div>
        <div className="recommendations">
          <div className="h3">Projects Timeline</div>
          <div className="recommendation"><span className="replaceTitle">-- Seal Air Leaks and Increase Attic Insulation 11". Suggested Year:  </span><span className="replaceYr h5" style={{border: `solid 2px ${props.atticGradeColor}`}}>{props.atticRecommendation}</span></div>
          <div className="recommendation"><span className="replaceTitle">-- Replace Water Heater with Electric Heat Pump. Suggested Year:  </span><span className="replaceYr h5" style={{border: `solid 2px ${props.waGradeColor}`}}>{props.waRecommendation}</span></div>
          <div className="recommendation"><span className="replaceTitle">-- Replace Space Heater with Electric Heat Pump. Suggested Year:  </span><span className="replaceYr h5" style={{border: `solid 2px ${props.spGradeColor}`}}>{props.spRecommendation}</span></div>
        </div>
    </div>
  )
}
export default Exam


// <div>Water Heater : age - {waHeaterAge}</div>
// <div>Energy Efficiency Factor: {waEfficencyGrade}</div>
// <div>Space Heater : {spHeaterAge} </div>
// <div>Energy Efficiency Factor: {spEfficency}</div>
