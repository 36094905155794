import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Nav from '../../../Nav';
import LoadingPage from '../../../Shared/Loading/Loading';
import ReactTooltip from "react-tooltip";
import * as PhotoService from '../../../utils/photoUploadService';
import editService from '../../../utils/editService';
import ValidationMessage from '../../../Shared/ValidationMessage/ValidationMessage';
import * as FormService from '../../../utils/formService';

class EditAttic extends Component {
  constructor(){
    super();
    this.state = {
      attic : {
        atticImg: null,
        atticType : '',
        atticSqft: '',
        atticDepth: '',
        insulMaterial: '',
        airSealed: '',
        userId: '',
        valid: {
          atticDepth: false
        }
      },
      preview1: null,
      selectedFile : null,
      loading: true
    }
  }
  componentDidMount(){
    this.getHouseInfo()
    this.setState({
      ...this.state,
      loading: false
    })
  };

  getHouseInfo = async() => {
    const userId = this.props.userId;

    try{
      const response = await fetch(`${process.env.REACT_APP_API}/api/v1/users/` + `${userId}`,  {
        credentials: 'include'
      });

      if(!response.ok){
        throw Error(response.statusText)
      };

      const userParsed = await response.json();

      let atticInfo = userParsed.attic;
      atticInfo.valid = {};
      
      atticInfo.atticDepth && atticInfo.atticDepth !== 'Select' ? atticInfo.valid.atticDepth = true : atticInfo.valid.atticDepth = false;
      console.log(atticInfo, "spaceHeater Info<------- Validations")

      this.setState({
        ...this.state,
        attic: {...atticInfo,
        }
        
      });
      console.log(this.state, "<------ this.state on load from db");
    } catch(err) {
      return err
    };
  };

  handleInput = e => {
    FormService.handleInput.call(this, e, 'attic', ['atticDepth']);

    // const updatedChange = {
    //   ...this.state.attic
    // };

    // updatedChange[e.target.name] = e.target.value;

    // this.setState({
    //   attic: updatedChange
    // });
  };

  handleClick = e => {
    var frame = document.getElementById(`input-${e.target.id}`)
    frame.click();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      ...this.state,
      loading: true
    })
    if(this.state.attic.valid.atticDepth && this.state.attic.airSealed && (this.state.preview1 || this.state.attic.atticImg)){

    const updatedAttic = {
      ...this.state.attic
    };
    console.log(updatedAttic, "<---- updated attic");
    this.editAttic(updatedAttic);

    this.setState({
      attic : {
        atticImg: null,
        atticType : '',
        atticSqft: '',
        atticDepth: '',
        insulMaterial: '',
        airSealed: '',
        userId: '',
      }
    });
  } else {
    this.setState({
      ...this.state,
      submitted: true,
    })
  }
  };


  fileSelectHandler = async function (e) {
    let objectName = "attic";
    await PhotoService.handleUploadFile.call(this, e, objectName);

  }.bind(this);

  handleEditFormInput = e => {
    this.setState({
      attic: {
        ...this.state.attic,
        [e.target.name]:  e.target.value
      }
    });
  };

  editAttic = async(e) => {
    
    this.setState({
      ...this.state,
      loading: true
    })
    const data = new FormData();
    let lastphoto = this.state.attic.atticImg.length - 1;
    if(typeof this.state.attic.atticImg[lastphoto] === "string"){
      console.log("no photo upload");
    } else{
      data.append('atticImg', this.state.attic.atticImg[lastphoto], "atticImage.jpg");
    }
    data.append('atticType', this.state.attic.atticType);
    data.append('atticSqft', this.state.attic.atticSqft);
    data.append('atticDepth', this.state.attic.atticDepth);
    data.append('insulMaterial', this.state.attic.insulMaterial);
    data.append('airSealed', this.state.attic.airSealed);
    // data.append('time', this.state.house.time);

    const userId = this.props.userId;
    
    data.append('userId', userId)
    // const time = new Date();
    // data.append('postingTime', time)

    editService.editOneDocument(this.state, 'attic', data, userId, this.props.history, this.props.renderNav);

    // axios.put(`${process.env.REACT_APP_API}/api/v1/attic/${userId}`, data, {
    //   headers: {
    //     'content-type': 'multipart/form-data',
    //     'Authorization': 'Bearer ' + tokenService.getToken()
    //   }
    // })
    // .then(res => {
    //   console.log(userId);
    //   this.props.history.push(`/mycasa/${userId}` );
    // })
  };

  deleteMyAttic = async(id, e) => {
    e.preventDefault()
    const userId = this.props.userId;
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/v1/Attic/` + `${userId}`, {
        method: 'DELETE',
        credentials: 'include'
      });

      if(!response.ok){
        throw Error(response.statusText)
      };

      this.props.history.push('/mycasa/' + userId);
    } catch(err) {
      alert('Something went wrong. Please try again')
    }
  };

  render(){
    // const userId = sessionStorage.getItem('userId');
    const userId = this.props.userId;
    const atticTypeOptions = ["Select", "Accessible by Hatch or Door", "Not Accessible", "No Attic"];
    const atticDepthOptions = ["Select", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"];
    const insulMaterialOptions = ["Select", "Fiberglass Batt", "Fiberglass Blown", "Cellulose", "Not Sure"];
    let upload = "./../../../upload.svg";

    if(this.state.loading){
      return <LoadingPage />
    } else {
    return (
      <div>
        {
          this.props.renderNav ?
          <Nav userId={this.props.userId} handleLogout={this.props.handleLogout}/>
        :
          ""
        }
        <div className="editContainer">
          <div className="editTitle">Attic Insulation Details</div>
          <form onSubmit={this.handleSubmit}>
            <div className="editBox">
              <div className="helpContainer">
                <span className="inputLabel">PHOTO</span>
                <img data-tip data-for="helpTip" className="help" src="./../../help.svg"/>
                <ReactTooltip id="helpTip" place="bottom" effect="solid" className="tooltipContainer">
                  <img className="sampleImg" src="../../SampleImages/atticSample.png"/>
                  <div className="sampleText">Use your flash to take a photo of your attic space, making sure to capture the depth of existing insulation.</div>
                </ReactTooltip>
              </div>
              <div className="frames">
                <img className="imgAttached"
                     id="photoOne"
                     className={this.state.preview1 ? "imgAttached" : "placer"}
                     src={this.state.preview1 ? this.state.preview1 :  this.state.attic.atticImg}
                     onClick={this.handleClick} />
                <input name="photoOne" className="fileUpload" id="input-photoOne" onChange={this.fileSelectHandler} type="file"/>
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="atticType">PRIMARY ATTIC TYPE</label>
                <select className="selectInput" name="atticType" id="atticType" type="text" onChange={this.handleInput} value={this.state.attic.atticType} >
                  {atticTypeOptions.map(atticTypeOption => {
                      return <option value={atticTypeOption} key={atticTypeOption} >{atticTypeOption}</option>
                    })}
                </select>
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="atticSqft">ATTIC SQUARE FOOTAGE (GUESS)</label>
                <input name="atticSqft" id="atticSqft" type="text" onChange={this.handleInput} value={this.state.attic.atticSqft} />
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="atticDepth">
                  INSULATION AVERAGE DEPTH (INCHES)*
                  <span className="tipContainer">
                    <img data-tip data-for="tip" className="help" src="./../../help.svg"/>
                    <ReactTooltip id="tip" place="bottom" effect="solid" className="tip">
                      <div className="tipText">Use a tape measure to determine the thickness of the insulation material between the roof and the attic floor. Insulation should be full and continuous across the surface to be effective. If there are gaps, or a mix of low and high materials, enter the lower number in the range. For questions, email north@housegrades.com.</div>
                    </ReactTooltip>
                  </span>
                </label>
                <select className={!this.state.attic.valid.atticDepth && this.state.submitted ? "selectInput notValid" : "selectInput"} name="atticDepth" id="atticDepth" type="text" onChange={this.handleInput} value={this.state.attic.atticDepth} >
                  {atticDepthOptions.map(atticDepthOption => {
                      return <option value={atticDepthOption} key={atticDepthOption} >{atticDepthOption}</option>
                    })}
                </select>
                {!this.state.attic.valid.atticDepth && this.state.submitted ? <ValidationMessage text={'Please enter an insulation depth.'}/> : ''}
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="insulMaterial">INSULATION MATERIAL</label>
                <select className="selectInput" name="insulMaterial" id="insulMaterial" type="text" onChange={this.handleInput} value={this.state.attic.insulMaterial} >
                  {insulMaterialOptions.map(insulMaterialOption => {
                    return <option value={insulMaterialOption} key={insulMaterialOption} >{insulMaterialOption}</option>
                  })}
                </select>
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="airSealed">HAS THE HOUSE BEEN PROFESSIONALLY AIR SEALED?*</label>
                <div id="airSealed" >
                  <input name="airSealed" type="radio" checked={this.state.attic.airSealed === "Yes"} value="Yes" onChange={this.handleInput}/><span className="radioNext">YES</span>
                  <input className="radioInput-right" name="airSealed" type="radio" checked={this.state.attic.airSealed === "No"} value="No" onChange={this.handleInput}/><span className="radioNext">NO</span>
                </div>
                {!this.state.attic.airSealed && this.state.submitted ? <ValidationMessage text={'Please select Yes or No.'}/> : ''}
              </div>
              <div className="inputContainer">
                <button type="submit" className="btn">SAVE</button>
                <button className="deleteBtn" onClick={this.deleteMyAttic.bind(null, userId)}>DELETE</button>
                {/* <button className="deleteBtn" onClick={this.deleteMyAttic(userId, e)}>DELETE</button> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  };
};

export default withRouter(EditAttic);
