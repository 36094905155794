import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './ValidationMessage.css';


export default function ValidationMessage(props){
    return(
        <Container>
            <Row>
                <Col style={{paddingLeft: '0px'}}>
                    <img className='validation-warning' src='../../SVG/Warning_sign.svg' alt='warning'/>
                    <span className='validation-text'>{props.text}</span>
                </Col>
                
            </Row>
        </Container>
    )
}