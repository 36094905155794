import tokenService from '../utils/tokenService';
import axios from 'axios';

function editOneDocument(state, modelName, photoData, userId, history, renderNav){
    if(state.preview1){
        
        axios.put(`${process.env.REACT_APP_API}/api/v1/${modelName}/${userId}`, photoData, {
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization': 'Bearer ' + tokenService.getToken()
          }
        })
        .then(res => {
          console.log(userId);
          console.log(renderNav, "<----------- renderNav)");
          history.push(`/mycasa/${userId}`)
          renderNav ? history.push(`/mycasa/${userId}`) : history.push(`/admin/home`);
          // renderNav ? history.push(`/mycasa/${userId}`) : history.goBack()
          // renderNav ? history.push(`/mycasa/${userId}`) : history.push(`/admin/${userId}`)
          
          ;
        })
      }else{
        // this is to account for editservice requirement of camelcase spelling
        // and mongo schema inconsistency for waHeaterType and waHeatertype
        // it's waHeatertype in Mongo
        
        if(state.waHeater){
          if(state.waHeater.waHeatertype){
            state.waHeater.waHeatertype = state.waHeater.waHeaterType
          }
        }
        const noPhotoBody = JSON.stringify(state[modelName])
        console.log(noPhotoBody, "data from noPhoto edit")
        axios.put(`${process.env.REACT_APP_API}/api/v1/nophoto/${modelName}/${userId}`, noPhotoBody, {
          headers: {
            
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + tokenService.getToken()
          }
        })
        .then(res => {
          console.log(userId);
          console.log(renderNav, "<----------- renderNav)");
          history.push(`/mycasa/${userId}`)
          renderNav ? history.push(`/mycasa/${userId}`) : history.push(`/admin/home`);
          // renderNav ? history.push(`/mycasa/${userId}`) : history.goBack();
         
          // renderNav ? history.push(`/mycasa/${userId}`) : history.push(`/admin/${userId}`)
        })
      }
}

export default {
    editOneDocument
  };