import React from "react";
import ElectrifyLayout from "./ElectrifyLayout";
import './ElectrifyComponents.scss'

const ElectricVehicle = (props) => {
  return (

    <ElectrifyLayout
          userId={props.userId} handleLogout={props.handleLogout}
          title="Electric Vehicle"
          electrifyIcon="../Electrify/ElectricVehicle.svg"
          includedInRating='Not Rated ("NR") in Electrification Grade'
          p1="One of the advantages of having an all-electric home with solar is
              that, if sized correctly, the solar power system can generate
              enough electricity to charge an electric car with no energy bills
              and zero emissions."
          p2="To charge an electric car, add approximately 2kw to your solar
              design and consider having an electrician install a Level 2 charger."

    />

  );
};

export default ElectricVehicle;
