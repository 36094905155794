import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import '../../MyCasaDashboard/MyCasaDashboard.scss';
import { Card, Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap';


const ShowSpHeater = (props) => {
  let spHeater = props.spHeater || 
  {
    spHeaterImg: [],
    spHeaterType : '',
    spHeaterBrand: '',
    spHeaterYear: '',
    spHeaterCondition: '',
    coolingSystem: '',
    userId: '',
  }; 

  const photo = spHeater.spHeaterImg;
  const userId = props.user._id;

  const undisplayedProps = ["_id", "userId", "__v", "spHeaterImg"]  
  const displayPropsArr = Object.keys(spHeater).filter((allProps) =>
    !undisplayedProps.includes(allProps));
  // console.log(displayPropsArr, "<-----displaypropsarr");

  // uses map function to format and populate quick show with display props
  let items = displayPropsArr.map((item, index) => (
    <div key={index}>
      {spHeater[item]
      ? 
      <>
        <span className="checkNext"><img src="./../checkMark.svg" /></span>
        <span>{(item[0].toUpperCase() + item.split(/(?=[A-Z])/).join(' ').slice(1)).replace("Sp", "Space")}: {spHeater[item]}</span>
      </>
      :
      <>
        <span className="checkNext gray"><img src="./../checkMark.svg" /></span>
        <span className="gray">{(item[0].toUpperCase() + item.split(/(?=[A-Z])/).join(' ').slice(1)).replace("Sp", "Space")}</span>
      </>
      }
    </div>    
  ))  

  // sets custom bootstrap toggle with expansion awareness
  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    // const currentEventKey = useAccordionEventKey();;
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <div className="detailArr"><img className="down" src={!isCurrentEventKey ? "./../../dropdown_drop.svg" : "./../../dropdown_up.svg"} /></div>
    );
  }

  return(
    <div className="postedContainer">
      <div className="postedFrame">
     {props.spHeater ? 
        <Link to={"/mycasa/spheater/edit"}><img className="postedImg" src={photo}/></Link>
        :
        <Link to="/mycasa/spheater/create" className="blankContainer">
          <div className="blankFrame"><img className="placer" src="./../../upload.svg" /></div>
        </Link>  
      }  
      </div>
      <Accordion className="accordion">
        <Card className="card">
          <Card.Header className="header">
            <Accordion.Toggle variant="link" eventKey="0" className="toggleShow">
              <div className="postedLabel">Heating and Cooling</div>
              <ContextAwareToggle eventKey="0"></ContextAwareToggle>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="cardBody">
              <div className="postedInfo">
                {/* <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.spHeater.spHeaterType}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span>Brand: <span>{props.spHeater.spHeaterBrand}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span>Year Built: <span>{props.spHeater.spHeaterYear}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span>Condition: <span>{props.spHeater.spHeaterCondition === "Yes" ? "Good" : "Not Good"}</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span>Cooling System: <span>{props.spHeater.coolingSystem}</span></div> */}
                {items}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  )
}
export default ShowSpHeater
