import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../public/upload.svg'

export default function LoadingPage(props){
    return(
        <Container>
            <Row>
                {/* <Col xs={{span: 4, offset: 4}}> */}
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <img id="photoOne" style={{width: '95px'}} src='./../../../upload.svg' />
                    </div>
                {/* </Col> */}
            </Row>
            <Row>
                {/* <Col xs={{span: 3, offset: 5}}> */}
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <span id="photoOne" style={{fontSize: '14px'}}>Click to upload</span>
                    </div>
                {/* </Col> */}
            </Row>
        </Container>
    )
}