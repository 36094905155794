import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import '../../MyCasaDashboard/MyCasaDashboard.scss';
import { Button, Card, Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap';


const ShowHouse = (props) => {
  let house = props.house || 
    {
      houseImg:[],
      address : '',
      city:'',
      state: '',
      zipcode: '',
      houseYear: '',
      houseSqft: '',
    }; 

  const photo = house.houseImg;
  const userId = props.user._id;

  const undisplayedProps = ["_id", "userId", "__v", "houseImg"]  
  const displayPropsArr = Object.keys(house).filter((allProps) =>
    !undisplayedProps.includes(allProps));
  // console.log(displayPropsArr, "<-----displaypropsarr");

  // uses map function to format and populate quick show with display props
  let items = displayPropsArr.map((item, index) => (
    <div key={index}>
      {house[item]
      ? 
      <>
        <span className="checkNext"><img src="./../checkMark.svg" /></span>
        <span>{item[0].toUpperCase() + item.split(/(?=[A-Z])/).join(' ').slice(1)}: {house[item]}</span>
      </>
      :
      <>
        <span className="checkNext gray"><img src="./../checkMark.svg" /></span>
        <span className="gray">{item[0].toUpperCase() + item.split(/(?=[A-Z])/).join(' ').slice(1)}</span>
      </>
      }
    </div>    
  ))

  // sets custom bootstrap toggle with expansion awareness
  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <div className="detailArr"><img className="down" src={!isCurrentEventKey ? "./../../dropdown_drop.svg" : "./../../dropdown_up.svg"} /></div>
    );
  }

  return(
    <div className="postedContainer">
      <div className="postedFrame">
      {props.house ? 
        <Link to={"/mycasa/house/edit"}><img className="postedImg" src={photo}/></Link>
        :
        <Link to="/mycasa/house/create" className="blankContainer">
          <div className="blankFrame"><img className="placer" src="./../../upload.svg" /></div>
        </Link>  
      }        
      </div>
      <Accordion className="accordion">
        <Card className="card">
          <Card.Header className="header">
            <Accordion.Toggle variant="link" eventKey="0" className="toggleShow">
              <div className="postedLabel">House Details</div>
              <ContextAwareToggle eventKey="0"></ContextAwareToggle>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="cardBody">
              <div className="postedInfo">
                {/* <div><span className="checkNext"><img src="./../checkMark.svg" /></span>{props.house.address}</div>
                <div>
                  <span className="checkNext"><img src="./../checkMark.svg" /></span>
                  <span>{props.house.city}</span>
                  <span className="spanNext">{props.house.state}</span>
                  <span className="spanNext">{props.house.zipcode}</span>
                </div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.house.houseSqft}</span><span className="spanNext">Square Feet</span></div>
                <div><span className="checkNext"><img src="./../checkMark.svg" /></span><span>{props.house.houseYear}</span><span className="spanNext">Year Built</span></div> */}
                {items}
              </div>
            </Card.Body>
           </Accordion.Collapse>
          </Card>
        </Accordion>
    </div>
  );
};

export default ShowHouse;
