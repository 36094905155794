import React, { Component } from 'react';
import Nav from '../Nav';
import LoadingPage from '../Shared/Loading/Loading';
import tokenService from '../utils/tokenService';

class RegisterLoginContainer extends Component {
  constructor(props){
    super(props)

    this.state = {
      register: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        phNumber: '',
        emailNotice: '',
        mobileNotice: ''
      },
      loading: false
    }
  }


  handleRegisterSubmit = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      loading: true
    })
    const updatedRegister = {
      ...this.state.register
    }

    //add regex later!
    const regex =  true;

    if(regex){
      this.fetchRegister(updatedRegister)
      this.setState({
        register: {
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          phNumber: '',
          emailNotice: '',
          mobileNotice: ''
        }
      })
    }else{

      this.setState({
        register: updatedRegister
      })
    }
  }

  handleRegisterChange = (e) => {
    const updatedChange = {
      ...this.state.register
    }
    updatedChange[e.target.name] = e.target.value;

    this.setState({
      register: updatedChange
    })
  }


  fetchRegister = async(updatedRegister) => {
    console.log("fetch firing");
    try{
      // move to userService.signup
      // await userService.signup(updatedRegister)
      console.log(updatedRegister, "Updated Register <-----");
      const response = await fetch(`${process.env.REACT_APP_API}/api/v1/auth/signup`, {
        method: 'POST',
        // credentials: 'include',
        body: JSON.stringify(updatedRegister),
        headers: new Headers({'Content-Type': 'application/json'})
      })
      .then(res => {
        if (res.ok) return res.json();
        // Probably a duplicate email
        throw new Error('Email already taken!');
      })
      // destructuring the token from the res.json() above
      .then(({token}) => tokenService.setToken(token));

    // const parsedResponse = await response.json();
    // console.log(parsedResponse, "parsedResponse from fetch Register <------------")
    // sessionStorage.setItem('userId', parsedResponse.userId);
    console.log("fetch complete<--===---====---===---===--==")
    this.props.handleSignUpOrLogin();
    console.log("handlesignup complete<--===---====---===---===--==")
    this.props.history.push(this.props.redirectPage)
    console.log("history complete<--===---====---===---===--==")
    }catch(err){
      //this is a useState hook vvv
      // setError(err.message)
      alert('This email address has been taken. Do you already have an account?')
    }
  }

  render(){
    if(this.state.loading){
      return <LoadingPage />
    } else {
    return(
      <div>
        <Nav userId={this.props.userId} handleLogout={this.props.handleLogout}/>
        <div className="registerContainer">
          <form onSubmit={this.handleRegisterSubmit}>
            <div className="formContainer">
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="email">EMAIL</label>
                <input type="email" name="email" value={this.state.register.email} onChange={this.handleRegisterChange} required/>
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="password">PASSWORD</label>
                <input type="password" name="password" value={this.state.register.password} onChange={this.handleRegisterChange} required/>
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="firstName">FIRST NAME</label>
                <input type="text" name="firstName" value={this.state.register.firstName} onChange={this.handleRegisterChange} required/>
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="lastName">LAST NAME</label>
                <input type="text" name="lastName" value={this.state.register.lastName} onChange={this.handleRegisterChange} required/>
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="phNumber">PHONE NUMBER</label>
                <input type="text" name="phNumber" value={this.state.register.phNumber} onChange={this.handleRegisterChange} required/>
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="emailNotice">EMAIL NOTIFICATION</label>
                <div id="emailNotice" >
                  <input name="emailNotice" type="radio" checked={this.state.register.emailNotice === "yes"} value="yes" onChange={this.handleRegisterChange}/><span className="radioNext">YES</span>
                  <input className="radioInput-right" name="emailNotice" type="radio" checked={this.state.register.emailNotice === "no"} value="no" onChange={this.handleRegisterChange}/><span className="radioNext">NO</span>
                </div>
              </div>
              <div className="inputContainer">
                <label className="inputLabel" htmlFor="mobileNotice">MOBILE NOTIFICATION</label>
                <div id="mobileNotice" >
                  <input name="mobileNotice" type="radio" checked={this.state.register.mobileNotice === "yes"} value="yes" onChange={this.handleRegisterChange}/><span className="radioNext">YES</span>
                  <input className="radioInput-right" name="mobileNotice" type="radio" checked={this.state.register.mobileNotice === "no"} value="no" onChange={this.handleRegisterChange}/><span className="radioNext">NO</span>
                </div>
              </div>
              <div className="inputContainer">
                <button className="btn" type="submit">SIGN UP</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
  }
}
export default RegisterLoginContainer