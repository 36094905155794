import React from 'react';
import {Link} from 'react-router-dom';
import './SubNav.css';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Pointer from './Pointer/Pointer';

export default function SubNav(props){
    const navLinks = {
        'show': {
            'Data': "#",
            'Analysis': '#',
            'Projects': '#',
            'Account': '#',
            'Notes': '#'
        },
        'index':{
            'All': "#",
            'Verified': '#',
            'Attention': '#',
            'Completed': '#',
            'Drafts': '#'
        }
    }

    const sliderClasses = ['one', 'two', 'three', 'four', 'five']

    function displayListItems (content){
        //Object.entries
        const contentArray = [];
        for (const [idx, [item, value]] of Object.entries(Object.entries(content))) {
            contentArray.push(
                
                        <li key={idx} className="sub-nav-list-item">
                            <Link className={item === props.activeLink ? " sub-nav-link sub-nav-active" : " sub-nav-link"} to='#' onClick={() => props.handleClick(item)}>
                                {item}
                            </Link>
                            {item === props.activeLink ? <Pointer page={props.page}/> : ""}
                            
                        </li>
                   
            )
          }
        return contentArray;
    }

    return(
        <>
       <Row style={{marginTop: '20px'}}>
        <Col md={props.page === 'index' ? '' : {span: 10, offset: 1}}>

                <div className="subnav">
                <ul className="sub-nav-list">
                    {props.page ? displayListItems(navLinks[props.page]) : ""}
                    
                    {/* <li className="one"><a href="#">Uno</a></li>
                    <li className="two"><a href="#">Dos</a></li>
                    <li className="three"><a href="#">Tres</a></li>
                    <li className="four"><a href="#">Quatro</a></li>
                    <hr className="underline"/>
                    */}
                </ul>
                
                </div>
                
        </Col>
        </Row>
        <Row style={{marginTop: '-16px'}}>
        <Col md={props.page === 'index' ? 12 : {span: 10, offset: 1}}>
            <hr className="subNav-hr"/>
        </Col>
        </Row>
        </>
    )

}




