import React, { Component } from 'react';
import LoadingPage from '../../Shared/Loading/Loading';
import ShowHouse from '../HouseContainer/ShowHouse';
import ShowAttic from '../AtticContainer/ShowAttic';
import ShowRoof from '../RoofContainer/ShowRoof';
import ShowSpHeater from '../SpHeaterContainer/ShowSpHeater';
import ShowWaHeater from '../WaHeaterContainer/ShowWaHeater';
import ShowUtility from '../UtilityContainer/ShowUtility';
import './MyCasaDashboard.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import axios from 'axios'
// import Moment from 'react-moment';

class MyCasaDashboard extends Component {
  constructor(props){
    super(props)
    this.state = {
      roof: '',
      spHeater:'',
      waHeater:'',
      attic:'',
      house: '',
      utility: '',
      myHouse: [],
      loading: false
    }
  };

  componentDidMount(){
    this.setState({
      ...this.state,
      loading: true
    })
    this.getHouseInfo();
  };

  getHouseInfo = async() => {
    // const userId = sessionStorage.getItem('userId');
    const userId = this.props.user._id;

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/api/v1/users/` + `${userId}`,  {
        credentials: 'include'
      });

      if(!response.ok){
        throw Error(response.statusText)
      };

      const userParsed = await response.json();

      this.setState({
        house: userParsed.house,
        attic: userParsed.attic,
        roof: userParsed.roof,
        waHeater: userParsed.waHeater,
        spHeater: userParsed.spHeater,
        utility: userParsed.utility,
        myHouse: userParsed.house,
        loading: false
      });
      // console.log(userParsed.attic, "<---------userparsed.attic");
    } catch(err) {
      return err
    };
  };

  render(){
    const userId = sessionStorage.userId;
    if(this.state.loading){
      return <LoadingPage dataPage={true}/>
    } else {
    return(
      <>
        {/* <Nav handleLogout={this.props.handleLogout} userId={this.props.userId}/>
        <div className="myCasaContainer">
          <div className="titleContainer myCasaTitle">
            <div className="title h2">My Casa</div>
            <div className="subtitle h4">Upload photos of your home energy assets to see how they compare to new technologies.</div>
          </div> */}
         
        <div className="showContainer">
          {/* <Container> */}
          <div className="showRow">
          <Row>
            {/* <Col md={4}>
            <div className="items">
              { this.state.house !== null
              ?
               <ShowHouse house={this.state.house} deleteMyHouse={this.deleteMyHouse} user={this.props.user}/>
              :
              <Link to="/mycasa/house/create" className="blankContainer">
                <div className="blankFrame"><img className="placer" src="./../../upload.svg" /></div>
                <div className="blankLabel">House Details</div>
              </Link>
              }
            </div>
            </Col> */}
            <Col md={4}>
            <div className="items">
               <ShowHouse house={this.state.house} deleteMyHouse={this.deleteMyHouse} user={this.props.user}/>
            </div>
            </Col>

            {/* <Col md={4}>
            <div className="items">
              { this.state.roof !== null
              ?
              <ShowRoof roof={this.state.roof} deleteMyRoof={this.deleteMyRoof} user={this.props.user}/>
              :
              <Link to="/mycasa/roof/create" className="blankContainer">
                <div className="blankFrame"><img className="placer" src="./../../upload.svg" /></div>
                <div className="blankLabel">Roof Details</div>
              </Link>
              }
            </div>
            </Col> */}

            <Col md={4}>
            <div className="items">
              <ShowRoof roof={this.state.roof} deleteMyRoof={this.deleteMyRoof} user={this.props.user}/>
            </div>
            </Col>

            {/* <Col md={4}>
            <div className="items">
              { this.state.attic !== null
              ?
              <ShowAttic attic={this.state.attic} deleteMyAttic={this.deleteMyAttic} user={this.props.user}/>
              :
              <Link to="/mycasa/attic/create" className="blankContainer">
                <div className="blankFrame"><img className="placer" src="./../../upload.svg" /></div>
                <div className="blankLabel">Attic Insulation Details</div>
              </Link>
              }
            </div>
            </Col> */}

            <Col md={4}>
            <div className="items">
              <ShowAttic attic={this.state.attic} deleteMyAttic={this.deleteMyAttic} user={this.props.user}/>
            </div>
            </Col>

            </Row>
          </div>
          <div className="showRow">
            <Row>
              {/* <Col md={4}>
              <div className="items">
                { this.state.waHeater !== null
                ?
                <ShowWaHeater waHeater={this.state.waHeater} deleteMyWaHeater={this.deleteMyWaHeater} user={this.props.user}/>
                :
                <Link to="/mycasa/waheater/create" className="blankContainer">
                  <div className="blankFrame"><img className="placer" src="./../../upload.svg" /></div>
                  <div className="blankLabel">Water Heater Details</div>
                </Link>
                }
              </div>
              </Col> */}

              <Col md={4}>
              <div className="items">
                <ShowWaHeater waHeater={this.state.waHeater} deleteMyWaHeater={this.deleteMyWaHeater} user={this.props.user}/>
              </div>
              </Col>

              {/* <Col md={4}>
              <div className="items">
                { this.state.spHeater !== null
                ?
                <ShowSpHeater spHeater={this.state.spHeater} deleteMySpHeater={this.deleteMySpHeater} user={this.props.user}/>
                :
                <Link to="/mycasa/spheater/create" className="blankContainer">
                  <div className="blankFrame"><img className="placer" src="./../../upload.svg" /></div>
                  <div className="blankLabel">Heating and Cooling Details</div>
                </Link>
                }
              </div>
              </Col> */}
              <Col md={4}>
                <div className="items">
                  <ShowSpHeater spHeater={this.state.spHeater} deleteMySpHeater={this.deleteMySpHeater} user={this.props.user}/>
                </div>
              </Col>

              {/* <Col md={4}>
              <div className="items">
                { this.state.utility !== null
                ?
                <ShowUtility utility={this.state.utility} deleteMyUtility={this.deleteMyUtility} user={this.props.user}/>
                :
                <Link to="/mycasa/utility/create" className="blankContainer">
                  <div className="blankFrame"><img className="placer" src="./../../upload.svg" /></div>
                  <div className="blankLabel">Utility Bills (Optional)</div>
                </Link>
                }
              </div>
              </Col> */}
              <Col md={4}>
                <div className="items">
                  <ShowUtility utility={this.state.utility} deleteMyUtility={this.deleteMyUtility} user={this.props.user}/>
                </div>
              </Col>
            
            </Row>
          </div>
          {/* </Container> */}
        </div>
        
      {/* </div> */}
      </>
    )
  }
  }
}

export default MyCasaDashboard;
