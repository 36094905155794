import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import LoadingPage from "../../../Shared/Loading/Loading.jsx";
import Nav from '../../../Nav'
import ReactTooltip from "react-tooltip";
import tokenService from '../../../utils/tokenService';
import * as PhotoService from '../../../utils/photoUploadService';
import PhotoUploadLink from '../../../Shared/PhotoUploadLink/PhotoUploadLink';
import ValidationMessage from '../../../Shared/ValidationMessage/ValidationMessage';


class CreateRoof extends Component {
  constructor(props){
    super(props)
    this.state = {
      roof : {
        roofImg: [],
        exterior : '',
        roofColor: '',
        pvSystem: '',
        panels: '',
        dcCapacity: '',
        userId: '',
      },
      preview: null,
      selectedFile : null,
      submitted: false,
      loading: false
    }
  };

  handleInput = e => {
    const updatedChange = {
      ...this.state.roof
    };

    updatedChange[e.target.name] = e.target.value;

    this.setState({
      roof: updatedChange
    });
  };

  handleClick = e => {
    var frame = document.getElementById(`input-${e.target.id}`);
    frame.click();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      ...this.state,
      loading: true
    })
    if(this.state.roof.pvSystem && this.state.preview1){
      const updatedRoof = {
        ...this.state.roof
      };
  
      this.addRoof(updatedRoof);
  
      this.setState({
        roof : {
          roofImg: [],
          exterior : '',
          roofColor: '',
          pvSystem: '',
          panels: '',
          dcCapacity: '',
          userId: '',
        }
      });
    } else {
      console.log('NOT VALID');
      this.setState({
        ...this.state,
        submitted: true,
        loading: false
      })
    }

  };

  fileSelectHandler = async function (e) {
    let objectName = "roof";
    await PhotoService.handleUploadFile.call(this, e, objectName);

  }.bind(this);

  // try using sharp to resize before upload?
  addRoof = async(updatedRoof) => {
    const data = new FormData();
    let lastphoto = this.state.roof.roofImg.length - 1;
    data.append('roofImg', this.state.roof.roofImg[lastphoto], "roofImage.jpg");
    data.append('exterior', this.state.roof.exterior);
    data.append('roofColor', this.state.roof.roofColor);
    data.append('pvSystem', this.state.roof.pvSystem);
    data.append('panels', this.state.roof.panels);
    data.append('dcCapacity', this.state.roof.dcCapacity);
    let userId = this.props.user._id;
    data.append('userId', userId)

    const time = new Date();
    data.append('postingTime', time)

    axios.post(`${process.env.REACT_APP_API}/api/v1/roof`, data, {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': 'Bearer ' + tokenService.getToken()
      }
    })
    .then(res => {
      if (res.status === 200) {
        this.props.history.push('/mycasa/' +  this.props.user._id);
      }
    })
  }

  render() {
    
    //redirects user to mycasa page once form data is saved to db
    // if (this.state.submitted) {
    //   this.props.history.push('/mycasa/' +  this.props.user._id);
    // }

    const extriorOptions = ["Select", "Shingles or Metal", "Wood Shakes", "Clay Tile", "Concrete Tile", "Tar & Gravel"];
    const colorOptions = ["Select", "Less than 15 years", "15 to 25 years", "More than 25 years", "Not Sure"];
    const upload = "./../../../upload.svg";
    const sampleRoofImg = "./../../SampleImages/RoofSample.jpg";
    if(this.state.loading){
      return <LoadingPage/>
    } else {
    return(
      <div>
        <Nav userId={this.props.userId} handleLogout={this.props.handleLogout}/>
        <div className="createContainer">
          <div className="createTitle">Roof Details</div>
          <form onSubmit={this.handleSubmit}>
            <div className="createBox">
              <div className="helpContainer">
                <span className="inputLabel">PHOTO *</span>
                <img data-tip data-for="helpTip" className="help" src="./../../help.svg"/>
                <ReactTooltip id="helpTip" place="bottom" effect="solid" className="tooltipContainer">
                  <img className="sampleImg" src={sampleRoofImg}/>
                  <div className="sampleText">Take a photo of your roof, capturing the roofing material and any Solar (PV) panels.</div>
                </ReactTooltip>
              </div>
            <div className="frames" style={this.state.submitted && !this.state.preview1 ? {border: '1px dashed #D22E2E'}: {}}>
            {this.state.preview1 ? <img className="imgAttached" id="photoOne" src={this.state.preview1}  onClick={this.handleClick } /> 
                : <div id="photoOne" onClick={this.handleClick }><PhotoUploadLink />
                    {this.state.submitted ? <ValidationMessage text={'Please upload a photo.'}/> : ''}
                </div>}
              {/* <img className={this.state.preview1 ? "imgAttached" : "placer"} id="photoOne" src={this.state.preview1 ? this.state.preview1 :  upload} onClick={this.handleClick } /> */}
              <input name="photoOne" className="fileUpload" id="input-photoOne" onChange={this.fileSelectHandler} type="file" />
            </div>
            <div className="inputContainer">
              <label className="inputLabel" htmlFor="exterior">EXTERIOR FINISH</label>
              <select className="selectInput" name="exterior" id="exterior" type="text" onChange={this.handleInput} value={this.state.roof.exterior}>
                {extriorOptions.map(extriorOption => {
                    return <option value={extriorOption} key={extriorOption} >{extriorOption}</option>
                })}
              </select>
            </div>
            <div className="inputContainer">
              <label className="inputLabel" htmlFor="roofColor">AGE</label>
              <select className="selectInput" name="roofColor" id="roofColor" type="text" onChange={this.handleInput} value={this.state.roof.roofColor}>
                {colorOptions.map(colorOption => {
                    return <option value={colorOption} key={colorOption} >{colorOption}</option>
                })}
              </select>
            </div>
            <div className="inputContainer">
              <label className="inputLabel" htmlFor="pvSystem">IS THERE SOLAR A PV SYSTEM INSTALLED?*</label>
              <div id="pvSystem" >
                <input className="radioInput" name="pvSystem" type="radio" checked={this.state.roof.pvSystem === "Yes"} value="Yes" onChange={this.handleInput}/><span className="radioNext">YES</span>
                <input className="radioInput-right" name="pvSystem" type="radio" checked={this.state.roof.pvSystem === "No"} value="No" onChange={this.handleInput}/><span className="radioNext">NO</span>
              </div>
            </div>
            {!this.state.roof.pvSystem && this.state.submitted ? <ValidationMessage text={'Please select Yes or No.'}/> : ''}
            <div className="inputContainer">
              <label className="inputLabel" htmlFor="panels">NUMBER OF PANELS</label>
              <input name="panels" id="panels" type="number" onChange={this.handleInput} value={this.state.roof.panels} />
            </div>
            <div className="inputContainer">
              <label className="inputLabel" htmlFor="dcCapacity">SIZE (KW)</label>
              <input name="dcCapacity" id="dcCapacity" type="number" onChange={this.handleInput} value={this.state.roof.dcCapacity} />
            </div>
            <div className="inputContainer">
              <button type="submit" className="btn">SAVE</button>
            </div>
            </div>
          </form>
        </div>
      </div>
    )
  };
  };
};


export default withRouter(CreateRoof);
