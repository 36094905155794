function handleUploadFile(e, objectName) {
  console.log(this, "<--- 1st---- this---")
  var file1;
  var dataUrl;
    switch (e.target.id) {
      case 'input-photoOne':
          file1 = e.target.files[0];
        break;

      default:
        console.log('file upload error');
        return 0;
    };
    
    var img = document.createElement('img');
    // var canvas = document.createElement('canvas');
    var reader1 = new FileReader();
    reader1.onload = function(e) {
        
        img.src = e.target.result;
        img.onload = function(img){
          
          var canvas = document.createElement('canvas');
          var resizedCanvas = resizeImage(img, canvas);
          resizedCanvas.toBlob(
            function (blob){
              
              this.setState({
                [objectName]: {
                  ...this.state[objectName],
                  [objectName + "Img"]: [this.state[objectName][objectName + "Img"], blob]
                }
              });
              
            }.bind(this), 'image/jpeg', .98
          );

        }.bind(this);
        
        this.setState({
          preview1: [reader1.result || null],
        })
        
    }.bind(this);
    if(file1){
      reader1.readAsDataURL(file1)
    }
    
    
    
  }

  function resizeImage(img, canvas){
    img = img.target;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // canvas size - 24 x 36 (2/3) | min file size - 1.5 mb | min pixels 1448 x 2170
    // adjusting size to 1024- https://cft.vanderbilt.edu/wp-content/uploads/sites/59/Image_resolutions.pdf
    var MAX_WIDTH = 1024;
    // var MAX_HEIGHT = 1448;
    // var MAX_WIDTH = 800;
    // var MAX_HEIGHT = 600;
    var width = img.width;
    var height = img.height;
    //landscape
    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } 
    // portrait
    else {
      if (height > MAX_WIDTH) {
        width *= MAX_WIDTH / height;
        height = MAX_WIDTH;
      }
    }
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
 
    return canvas;
  }
  
  
  
  export {
    resizeImage,
    handleUploadFile
  };