import tokenService from '../utils/tokenService';

const BASE_URL = `${process.env.REACT_APP_API}/api/v1/address/`



  export function getAllAddresses(){
    return fetch(BASE_URL, {
        headers: {
          'Authorization': 'Bearer ' + tokenService.getToken(),
            credentials: 'include'
        }
      })
      .then(res => res.json());
  }

  export function getOneAddress(addressId){
      console.log(BASE_URL + addressId, "<-------address API firing")
    return fetch((BASE_URL + addressId), {
        headers: {
          'Authorization': 'Bearer ' + tokenService.getToken()
            // credentials: 'include'
        }
      })
      .then(res => res.json());
  }

  export function getOneAddressFromUserId(userId){
    console.log(BASE_URL + 'userAddress/' + userId, "Address API <---------= userId")
    return fetch((BASE_URL + 'userAddress/' + userId), {
      
      headers: new Headers({
          
          'Authorization': 'Bearer ' + tokenService.getToken(),
      })
     
    })
    .then(res => res.json());
  }

  export function validateOne(address){
    console.log(BASE_URL + address._id + "?_method=PUT", "<-------address API firing")
    return fetch((BASE_URL + address._id + "?_method=PUT"), {
        // 'Content-Type': 'application/json',
        method: 'POST',
        // credentials: 'include',
        body: JSON.stringify(address),
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + tokenService.getToken(),
        })
       
      })
      .then(res => res.json());

    }



  